/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Input } from 'antd';
import Icon from '../../../components/elements/Icon';
import GridView from '../../../components/elements/GridView/index';
import Loading from '../../../components/elements/Loading';
import { isEmpty } from 'underscore';

export default function () {
    const {
        actions, divisions, divisionsPagination, divisionIsFetching, user, handleTabChange,
    } = this.props;
    const {
        divisionSearchParams,
        divisionOrderParams,
        showAddDivisionModal,
        newDivisionName,
        errorMessage,
    } = this.state;

    const data = divisions.map((d) => ({
        division_id: d.division_id,
        name: d.name,
        number_of_assets: (
            <div onClick={e => { e.stopPropagation(); }}>
                <Link
                    to={{
                        pathname: '/assets/',
                        state: { currentTabKey: '1', data: [] },
                    }}
                    onClick={() => {
                        this.setState({ currentTabKey: '1', data: [] });
                        actions.resetAssetsRequest();
                        actions.filterAssetsDivisionRequest({
                            divisions: [parseInt(`${d.division_id}`)], currentTabKey: '1', page: 1, searchParams: { divisions: [parseInt(`${d.division_id}`)] },
                        });
                    }}>
                    {d.number_of_assets}
                </Link>
            </div>
        ),
        color: (
            isEmpty(d.color) ? null : <div style={{ height: 30, width: 30, border: "1px solid #999", padding: 2, borderRadius: 2}}><span style={{ backgroundColor: d.color, height: '100%', width: '100%', display: 'inline-block'}}></span></div>
        ),
        status: (
            d.status == 1 ? 'Active' : 'Inactive'
        ),
        actions: (
            user.permissions.adding_divisions === true
                ? (
                    <Link to={`/division/view/${d.division_id}`} className="btn btn-empty ml-2">
                        <Icon name="pencil" />
                    </Link>
                )
                : null
        ),
    }));

    const paginationChange = (page) => {
        if (page !== divisionsPagination.currentPage) {
            actions.getDivisionsGridRequest({ page, divisionSearchParams, divisionOrderParams });
        }
    };

    const gridChange = (pagination, filters, sorter) => {
        if (pagination && pagination.current == divisionsPagination.currentPage) {
            const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}` };
            this.setState({ divisionOrderParams: params });
            actions.getDivisionsGridRequest({ page: divisionsPagination.currentPage, ...divisionSearchParams, ...params });
        }
    };

    const handleOk = () => {
        if (!newDivisionName) {
            this.setState({ errorMessage: 'Please enter a division name' });
            return;
        }
        if (newDivisionName.length > 30) {
            this.setState({ errorMessage: 'Name cannot be more than 30 characters' });
            return;
        }

        // Clear the error message and submit the form
        this.setState({ errorMessage: '' });
        actions.createDivisionRequest({ name: newDivisionName });
        this.setState({ showAddDivisionModal: false });
    };

    const onCancel = () => {
        this.setState({ showAddDivisionModal: false });
        this.setState({ newDivisionName: '' });
    };

    return (
        (<div className="section__body">
            {divisionIsFetching ? <Loading /> : null}
            <div className="section__action-bar">
                {user.permissions.adding_divisions === true ? (
                        <Button
                            onClick={() => this.setState({ showAddDivisionModal: true })}
                            type="primary"
                            className='page-header-button'
                            style={{ right: '16px' }}
                        >
                            Add division
                        </Button>
                    )
                    : null}
            </div>
            <GridView
                data={data}
                onChange={gridChange}
                sortableColumns={['division_id', 'name', 'number_of_assets', 'color', 'status']}
                pagination={{
                    total: parseInt(divisionsPagination.totalRecords),
                    pageSize: parseInt(divisionsPagination.perPageCount),
                    showSizeChanger: false,
                    onChange: paginationChange,
                }} />
            <Modal
                open={showAddDivisionModal}
                onCancel={onCancel}
                onOk={handleOk}
                okText="Add"
            >
                <h3 className='title'>Create Division</h3>
                <label htmlFor="division-name-input">Name</label>
                <Input
                    id="division-name-input"
                    value={newDivisionName}
                    onChange={(v) => this.setState({ newDivisionName: v.target.value, errorMessage: '' })}
                    style={{ marginBottom: '2px' }}
                    maxLength={30}
                />
                {errorMessage && <div style={{ color: 'red', marginTop: '2px' }}>{errorMessage}</div>}
            </Modal>
        </div>)
    );
}
