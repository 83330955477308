/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isEmpty, size } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as assetsActions from '../../../../../core/asset/assetActions';
import CustomEmpty, { CustomEmptyType } from '../../../../../components/CustomEmpty';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            unit: false,
        };
    }

    componentDidMount() {
        const { profile } = this.props;
        const { unit_of_measurement_preference } = profile;

        if (unit_of_measurement_preference === 'british-imperial') {
            this.setState({ unit: true });
        } else {
            this.setState({ unit: false });
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    setUnit = (e) => {
        this.setState({ unit: e?.key === 'imperial' });
    };

    render() {
        const {
            mileageReport,
            isFetching,
        } = this.props;

        const { unit } = this.state;

        const convertedMileageReport = [];

        mileageReport.forEach((report) => {
            const newReport = {};
            const keys = Object.keys(report);

            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = report[key];

                if (unit && value != null && key != 'name' && key != 'id') {
                    // convert km to miles
                    if (!Number.isNaN(+value) && value != 0) {
                        const miles = value / 1.609344;
                        newReport[key] = miles.toFixed(2)
                            .toString();
                    } else if (parseFloat(value) === 0) {
                        newReport[key] = '-';
                    } else {
                        newReport[key] = value.toString();
                    }
                } else if (key == 'id') {
                    newReport[key] = Number(value)
                        .toFixed(0)
                        .toString();
                } else {
                    newReport[key] = value != null ? value.toString() : null;
                }
            }
            convertedMileageReport.push(newReport);
        });

        const listReports = convertedMileageReport.map((report) => {
            const trimmedReport = {
                ...report,
                name: (
                    <Link
                        to={`/assets/view/${report.id}`}
                        className="btn btn-empty">
                        {report.name}
                    </Link>
                ),
            };
            delete trimmedReport.id;
            return trimmedReport;
        });
        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        return (
            <Spin spinning={isFetching}>
                <br />
                <div className="report-table-with-totals">
                    {isEmpty(listReports) ? <CustomEmpty type={CustomEmptyType.Report} />
                        : (
                            <>
                                {size(listReports) === 1
                                    ? <CustomEmpty />
                                    : (
                                        <>
                                            <h4 className='report-title'>Mileage Report</h4>
                                            <Dropdown overlay={(
                                                <Menu onClick={this.setUnit}>
                                                    <Menu.Item key="imperial">Miles</Menu.Item>
                                                    <Menu.Item key="metric">Kilometers</Menu.Item>
                                                </Menu>
                                            )}>
                                                <Button 
                                                    type='primary'
                                                    className='export-dropdown-button'
                                                    style={{ 
                                                        right: 96, 
                                                        top: -14 
                                                    }}
                                                >
                                                    {unit ? 'Miles' : 'Kilometers'}
                                                    <DownOutlined />
                                                </Button>
                                            </Dropdown>
                                            <GridView
                                                showExportButton
                                                exportableColumns={exportableColumns}
                                                exportFileName=""
                                                data={listReports}
                                                onChange={this.onTableChange}
                                                disablePagination />
                                        </>
                                    )}
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    mileageReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    mileageReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        mileageReport: state.report.mileageReport,
        isFetching: state.report.isFetching,
        profile: state.user.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
