/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { User, UserMultiple, Help, Logout } from '@carbon/icons-react';
import { Row, Col, Layout, Menu as AntMenu, Dropdown, Modal, Select } from 'antd';
import lockr from 'lockr';
import { Erase } from '@carbon/icons-react';
import history from '../../../core/utils/history';
import srcFleetclearLogo from '../../../assets/images/fleetclear-logo.svg';
import Menu from '../Menu';
import Icon from '../../elements/Icon';

import './header.scss';
import { getIbmIconComponent } from '../../../core/utils/iconUtils';

const { MAP_SLIDER_INTERVAL, SHARD_KEY } = require('../../../core/constants').default;

const { Option } = Select;

export default function () {
    const {
        backLink,
        title,
        menuPlacement,
        menuWidth,
        floating,
        beforeHeader,
        afterHeader,
        user,
        companies,
    } = this.props;

    const { changeCompanyModalVisible } = this.state;

    const { deviceGroups, userCompany } = user;

    const allowedRoles = ['super-admin', 'admin', 'manager'];
    const customAllowedRoles = ['super-admin', 'admin', 'manager', 'user'];

    let isVideoVisible = false;
    if (user && user.permissions && (user.permissions.live_video === true || user.permissions.historic_video_downloading === true)) {
        isVideoVisible = true;
    }

    const navlist = [
        {
            label: 'Map', link: '/map', iconName: 'map', visible: true,
        },
        {
            label: 'Events', link: '/events', iconName: 'events', visible: true,
        },
        {
            label: 'Video', link: '/video/video-search', iconName: 'video', visible: isVideoVisible, forceRefresh: true,
        },
        {
            label: 'Dashboard', link: '/dashboard', iconName: 'data', visible: true,
        },
        {
            label: 'Assets', link: '/assets', iconName: 'assets', visible: (customAllowedRoles.length ? customAllowedRoles.includes(user.role) : true),
        },
        {
            label: 'Drivers', link: '/drivers', iconName: 'user', visible: (customAllowedRoles.length ? customAllowedRoles.includes(user.role) : true),
        },
        {
            label: 'Reports', link: '/reports', iconName: 'reports', visible: true,
        },
    ];

    const visibleItems = navlist.filter((item) => item.visible === true);

    const shardId = lockr.get(SHARD_KEY);

    let selectedDeviceGroup = {};
    if (deviceGroups.length > 1) {
        for (let i = 0; i < deviceGroups.length; i++) {
            const deviceGroup = deviceGroups[i];
            if (deviceGroup.id == shardId) {
                selectedDeviceGroup = deviceGroup;
            }
        }
    }

    const regionMenu = (
        <AntMenu>
            {deviceGroups.map((deviceGroup) => {
                if (deviceGroup.id != shardId) {
                    return <AntMenu.Item key={deviceGroup.id} onClick={this.handleShardChange}>{deviceGroup.label ? deviceGroup.label : deviceGroup.name}</AntMenu.Item>;
                }
                return null;
            })}
        </AntMenu>
    );

    const menu = (
        <AntMenu>
            <AntMenu.Item 
                key="0" 
                onClick={() => history.push('/profile')}
                style={{ lineHeight: '20px' }}
            >
                <User size={16} />
                &ensp;&ensp;
                My profile
            </AntMenu.Item>
            {allowedRoles.length && allowedRoles.includes(user.role) && (
                <AntMenu.Item 
                    key="1" 
                    onClick={() => history.push('/users')}
                    style={{ lineHeight: '20px' }}
                >
                    <UserMultiple size={16} />
                    &ensp;&ensp;
                    User management
                </AntMenu.Item>
            )}
            <AntMenu.Item 
                key="2" 
                onClick={() => window.location = 'https://help.fleetclear.com/'}
                style={{ lineHeight: '20px' }}
            >
                <Help size={16} />
                &ensp;&ensp;
                Fleetclear Help
            </AntMenu.Item>
            <AntMenu.Item 
                key="3" 
                onClick={this.logout}
                style={{ lineHeight: '20px' }}
            >
                <Logout size={16} />
                &ensp;&ensp;
                Logout
            </AntMenu.Item>
        </AntMenu>
    );

    const companiesSelect = (
        <Select
            onChange={(val) => { this.setState({ companySelectValue: val }); }}
            showSearch
            allowClear={<Erase size={12} />}
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            style={{ width: '100%' }}
            placeholder="Select Company"
        >
            {
                companies.map((v, i) => {
                    if (v.id !== userCompany.id) {
                        /* eslint-disable-next-line react/no-array-index-key */
                        return <Option key={i} value={v.id}>{v.title}</Option>;
                    }
                    return null;
                })
            }

        </Select>
    );

    return (
        (<div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row type="flex" justify="space-between">
                    <Col>
                        {backLink}
                        {visibleItems.length > 0 ? (
                            <Menu
                                items={visibleItems}
                                logo={srcFleetclearLogo}
                                placement={menuPlacement}
                                width={menuWidth} />
                        ) : null}
                        <div className="header__logos">
                            <img src={srcFleetclearLogo} className="header__logo-fleet" alt="Fleetclear" />
                        </div>
                    </Col>
                    <Col>
                        <h3 className="header__title h-4">{title}</h3>
                    </Col>
                    <Col>
                        <Row type="flex" justify="space-between">

                            {user.role === 'super-admin' && (
                                <div className="region">
                                    <div className="region__details">
                                        <div className="profile__username">Company</div>
                                        <div className="profile__user-position">{userCompany.title}</div>
                                    </div>
                                    <div className="region__dropdown" onClick={() => { this.setState({ changeCompanyModalVisible: true }); }}>
                                        <Icon name="triangle-down" />
                                    </div>
                                </div>
                            )}
                            {deviceGroups.length > 1 && (
                                <div className="region">
                                    <div className="region__details">
                                        <div className="profile__username">Region</div>
                                        <div className="profile__user-position">{selectedDeviceGroup.label ? selectedDeviceGroup.label : selectedDeviceGroup.name}</div>
                                    </div>
                                    <Dropdown overlay={regionMenu} trigger={['click']} placement="bottomRight">
                                        <div className="region__dropdown">
                                            <Icon name="triangle-down" />
                                        </div>
                                    </Dropdown>
                                </div>
                            )}
                            <div className="profile">
                                <div className="profile__user">
                                    <div className="profile__username">{(user.profile && user.profile.full_name) || user.loginUser}</div>
                                    <div className="profile__user-position">{user.role}</div>
                                </div>
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <div className="profile__dropdown">
                                        {user?.userCompany?.company_image ? (
                                            <img 
                                                src={user?.userCompany?.company_image}
                                                alt='Profile'
                                            />
                                        ) : (    
                                            <Icon name="person" />
                                        )}
                                    </div>
                                </Dropdown>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <nav className="navlist">
                    {visibleItems.map((item, itemIndex) => (
                        <NavLink
                            to={item.link}
                            className={`navlink ${item.className || ''}`}
                            activeClassName="is-active"
                            key={item.key || `nav-item-${itemIndex}`}
                            onClick={() => {
                                this.clickOnItem();
                                if (item?.forceRefresh) {
                                    setTimeout(() => window.location.reload(), 0);
                                }
                            }}
                        >
                            {item.iconName && (
                                <div className="navicon">
                                    {getIbmIconComponent(item.iconName, 12)}
                                </div>
                            )}
                            <span>{item.label}</span>
                        </NavLink>
                    ))}
                </nav>
            </Layout.Header>
            <Modal
                open={changeCompanyModalVisible}
                onCancel={() => this.setState({ changeCompanyModalVisible: false })}
                onOk={this.handleChangeCompany}
                okText="Change"
                onClose={() => this.setState({ changeCompanyModalVisible: false })}
            >
                <h3>Change Company</h3>
                {companiesSelect}
                <div style={{ height: '16px' }} />
            </Modal>
            {afterHeader}
        </div>)
    );
}
