import React from 'react';
import { Link } from 'react-router-dom';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DownOutlined } from '@ant-design/icons';
import { Button, Row, Col, Spin, Tag, Tooltip, Modal, Skeleton } from 'antd';
import Map from '../../components/Map';
import moment from 'moment';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import app from '../../core/constants';
import GridView from '../../components/elements/GridView';
import EventFilter from './forms/EventFilter';
import GeoFenceForm from './forms/GeoFenceForm';
import './eventTriggers.scss';
import history from '../../core/utils/history';

const { GOOGLE_MAP_API_KEY } = app;

export default function () {
    const {
        selectedTrigger, selectedTriggerEvents, selectedTriggerEventsPagination, isFetching, divisions, assets, user,
    } = this.props;
    const {
        showTriggerConditions, showEditTrigger, clearGeofence, showSaveButton,
    } = this.state;

    // geoFenceCords

    const listEvents = selectedTriggerEvents.map((selectedTriggerEvent) => {
        const iconStyle = {};
        iconStyle.fontSize = '16px';
        iconStyle.color = '#CF2B28';
        const tempEvent = {};
        tempEvent.event_id = selectedTriggerEvent.icon && selectedTriggerEvent.icon != '' ? <Tooltip title={selectedTriggerEvent.event_name}><LegacyIcon type={selectedTriggerEvent.icon} theme="filled" style={iconStyle} /></Tooltip> : null;
        tempEvent.date = moment(selectedTriggerEvent.time, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
        tempEvent.time = moment(selectedTriggerEvent.time, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a');
        tempEvent.location = `${selectedTriggerEvent.latitude}, ${selectedTriggerEvent.longitude}`;
        tempEvent.driver = <Link to={`/drivers/view/${selectedTriggerEvent.driver_id}`}>{selectedTriggerEvent.driver}</Link>;
        tempEvent.reg = <Link to={`/assets/view/${selectedTriggerEvent.asset_id}`}>{selectedTriggerEvent.registration}</Link>;
        return tempEvent;
    });

    const mapToolBarButtons = [
        { key: 'side-bar', visible: false },
        { key: 'info-box', visible: false },
        { key: 'traffic', visible: false },
        { key: 'geo-fence', onClick: this.toggleMapGeofence, visible: false },
        { key: 'center-map', visible: false },
        { key: 'satellite', visible: false },
        { key: 'heat-map', visible: false },
    ];

    const geoFenceBoundary = this.getGeofenceBoundary();

    return (
        (<Layout
            data-test="pages-event-trigger"
            className="event-triggers"
            title={<div>EventTriggers</div>}
            headerTitle={<div>EventTriggers Page</div>}
            showFooter={false}>
            <Spin spinning={isFetching}>
                <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                    <FilterSidebar>
                        {/* <EventFilter onSubmit={this.onFilterChange} /> */}
                    </FilterSidebar>
                    <div className="content">
                        <div className="content-inner">
                            <section className="section">
                                <div className="section__body">
                                    <Row
                                        gutter={40}
                                        type="flex"
                                        justify="space-between"
                                        className={`data-row ${showTriggerConditions ? 'fadein' : 'fadeout'}`}
                                        style={{ padding: '30px 0' }}>
                                        <Col xs={24} md={6} xl={6}>
                                            <p className="text-bold">
                                                Geo-fence Alert Name:
                                            </p>
                                            <p className="mt-1 fz-12">
                                                {selectedTrigger.name}
                                            </p>
                                            <div className="mt-2">
                                                <p className="text-bold">
                                                    Affected division:
                                                </p>
                                                {selectedTrigger.divisions && (selectedTrigger.divisions).length > 0
                                                    ? selectedTrigger.divisions.map((tag, index) => {
                                                        const isLongTag = tag.name.length > 20;
                                                        const tagElem = (
                                                            <Tag
                                                                key={tag.name}
                                                                closable={index !== 0}
                                                                className="mt-1"
                                                                onClose={() => this.handleClose(
                                                                    tag.name,
                                                                )}>
                                                                {isLongTag
                                                                    ? `${tag.name.slice(
                                                                        0,
                                                                        20,
                                                                    )}...`
                                                                    : tag.name}
                                                            </Tag>
                                                        );
                                                        return isLongTag ? (
                                                            <Tooltip
                                                                title={tag.name}
                                                                key={tag.name}>
                                                                {tagElem}
                                                            </Tooltip>
                                                        ) : (
                                                            tagElem
                                                        );
                                                    })
                                                    : 'All Assets'}
                                            </div>
                                        </Col>
                                        <Col xs={24} md={18} xl={13}>
                                            <p className="text-bold">
                                                Geo-fence map:
                                            </p>
                                            <div
                                                className="mt-2"
                                                style={{ height: 246 }}>
                                                <Map
                                                    ref={this.googleMapAccess}
                                                    showToolbar={false}
                                                    allowStreetView={false}
                                                    showFindCenterButton={false}
                                                    apiKey={GOOGLE_MAP_API_KEY}
                                                    mapToolBarButtons={mapToolBarButtons}
                                                    mapShapeOnClick={this.onMapShapeClick}
                                                    polygonBoundary={geoFenceBoundary}
                                                    zoom={9}
                                                    // markers={markers}
                                                    center={this.centerMap} />
                                                {/* <Skeleton active loading={isFetching}>

                                                </Skeleton> */}
                                                <Modal
                                                    open={showEditTrigger}
                                                    onCancel={() => this.setState({ showEditTrigger: false })}
                                                    footer={false}
                                                    width={388}>
                                                    <div className="d-flex dir-column align-center text-center">
                                                        <GeoFenceForm
                                                            onSubmit={this.saveTrigger}
                                                            redrawGeofence={this.redrawGeofence}
                                                            deleteGeofence={this.deleteGeofence}
                                                            divisions={divisions}
                                                            assets={assets}
                                                            initializeValues={selectedTrigger}
                                                            selectedDivisions={selectedTrigger.divisions} />
                                                        <Button
                                                            type="link"
                                                            onClick={(e) => this.setState({ showEditTrigger: false })}>Cancel
                                                        </Button>
                                                    </div>
                                                </Modal>
                                            </div>
                                        </Col>
                                        <Col xl={5} span={24}>
                                            <div
                                                className="actions"
                                                style={{
                                                    height: '100%',
                                                    paddingTop: '36px',
                                                }}>
                                                <div className="actions__buttons justify-end align-center">
                                                    <Button
                                                        onClick={() => {
                                                            if (showSaveButton) {
                                                                const values = {};
                                                                values.trigger_name = '';
                                                                values.selection = '';
                                                                values.divisions = '';
                                                                values.redraw = true;
                                                                this.saveTrigger(values);
                                                                return;
                                                            }
                                                            this.setState({ showEditTrigger: true });
                                                        }}
                                                        type="primary"
                                                        className="btn middlewide">
                                                        <Icon name="pencil" />
                                                        <span>{showSaveButton ? 'save' : 'edit'} GEO-FENCE</span>
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        className="btn middlewide mt-0"
                                                        onClick={this.deleteTrigger}>
                                                        <Icon name="basket" />
                                                        <span>delete GEO-FENCE</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="toggle-section-bar" onClick={() => this.setState({ showTriggerConditions: !showTriggerConditions })}>
                                        <Button className="btn btn-empty">
                                            {showTriggerConditions ? <Icon name="chevron-up" /> : <DownOutlined />}
                                        </Button>
                                        <p className="mt-1">
                                            {showTriggerConditions ? 'hide' : 'show'} conditions for this geo-fence
                                        </p>
                                    </div>
                                    <div className="mt-5">
                                        <GridView
                                            // filterTemplate='<div><div style="display: inline-block; width: 45%">{{label}}</div><div style="display: inline-block; width: 45%">{{element}}</div><div></div></div>'
                                            data={listEvents}
                                            onChange={this.onTableChange}
                                            sortableColumns={['event_id', 'date', 'time', 'driver', 'reg']}
                                            pagination={{
                                                total: parseInt(selectedTriggerEventsPagination.totalRecords, 10),
                                                showSizeChanger: false,
                                                pageSize: parseInt(selectedTriggerEventsPagination.perPageCount, 10),
                                                onChange: this.onPageChange,
                                            }} />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Spin>
        </Layout>)
    );
}
