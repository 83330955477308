import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    DownOutlined, FileExcelOutlined, FilePdfOutlined, FileTextOutlined,
} from '@ant-design/icons';
import {
    Row,
    Col,
    Input,
    Select,
    Radio,
    Checkbox,
    DatePicker,
    Menu,
    Dropdown,
    Button,
} from 'antd';
import parse from 'html-react-parser';
import { JsonToTable } from 'react-json-to-table';
import jsPDF from 'jspdf';
import { renderToString } from 'react-dom/server';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { findAndReplace } from './lib/helpers';
import history from '../../../../core/utils/history';
import TableView from './TableView';
import { Calendar, Erase } from '@carbon/icons-react';

import 'antd/dist/reset.css';

const { RangePicker } = DatePicker;

class GridView extends Component {
    constructor(props) {
        super(props);
        this.myTableId = `table-${Math.floor(Math.random() * 100) + 1}`;
        this.state = {
            customPageSize: null,
        };
    }

    changePageSize = (current, size) => {
        this.setState({ customPageSize: size });
    };

    titleCase = (str) => {
        const splitStr = str.toLowerCase().split('_');
        for (let i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    };

    getTextFromComponent = (comp) => {
        let value = '';
        if (typeof comp === 'string' || typeof comp === 'number') {
            value = comp;
        } else if (comp && comp.props && comp.props.children) {
            const { children } = comp.props;
            if (Array.isArray(children)) {
                children.forEach((child) => {
                    if (typeof child === 'string') {
                        value = child;
                    } else if (child.props) {
                        value = this.getTextFromComponent(child);
                    }
                });
            } else if (typeof children === 'string' || typeof children === 'number') {
                value = children;
            } else if (children.props) {
                value = this.getTextFromComponent(children);
            }
        } else {
            // Here we can define custom types that we want to process, this prevents us from trying to process invalid components like icons
            if (comp && comp.props && comp.props.defaultLocation) {
                value = comp.props.defaultLocation;
            }
        }
        return value;
    };

    exportCsv = ({ key }) => {
        const { data, exportFileName, exportableColumns } = this.props;

        const { columns, customFilterTemplate } = this.getColumnsAndFilter();
        let exportColumns = (columns.filter((col) => col.exportable === true && col.title != 'Key'));
        if (Array.isArray(exportableColumns) && exportableColumns.length > 0) {
            if (typeof exportableColumns[0] === 'string') {
                exportColumns = exportColumns.filter((eC) => exportableColumns.includes(eC.key));
            }
        }

        const exportData = data.map((row) => {
            const tempRow = {};
            exportColumns.forEach((expCol) => {
                tempRow[expCol.title] = this.getTextFromComponent(row[expCol.key]);
            });
            return tempRow;
        });
        const fileName = exportFileName.split('.');
        const csvHead = exportColumns.map((eC) => `"${eC.title}"`);
        const csvBody = exportData.map((eD) => (Object.values(eD)).map((eComma) => `"${eComma}"`));
        const csv = [
            csvHead,
            ...csvBody,
        ].join('\r\n');
        const csvBlob = new Blob([csv], { type: 'text/csv; charset=utf-8' });
        saveAs(csvBlob, `${fileName[0]}.csv`);
    };

    fitToColumn = (data) => {
        const columnWidths = [];
        for (const property in data[0]) {
            columnWidths.push({ wch: Math.max(property ? property.toString().length : 0, ...data.map((obj) => (obj[property] ? obj[property].toString().length : 0))) });
        }
        return columnWidths;
    };

    getColumnsAndFilter = (includeActions = false) => {
        const {
            data, sortableColumns, filterColumns, exportableColumns,
            showExportButton, filterTemplate, hiddenColumns, overrideColumnWidths,
        } = this.props;

        /* Find highest no. of keys object */
        const customFilterTemplate = [];
        let maxColumn = 0;
        let columnObject = {};

        data.forEach((row) => {
            if (Object.keys(row).length > maxColumn) {
                columnObject = row;
                maxColumn = Object.keys(row).length;
            }
        });
        const columns = Object.keys(columnObject)
            .filter((column) => column != 'children')
            .filter((column) => column != 'key')
            .filter((column) => column != 'nestedRows')
            .filter((column) => {
                let columnIsVisible = true;
                for (let i = 0; i < hiddenColumns.length; i++) {
                    if (hiddenColumns[i] === column) {
                        columnIsVisible = false;
                    }
                }
                return columnIsVisible;
            })
            .filter((column) => {
                if (includeActions) return true;

                return column != 'actions';
            })
            .map((column, columnIndex) => {
                const isSortable = sortableColumns.includes(column);
                const filter = filterColumns.filter((filterColumn) => filterColumn.key == column)[0] || null;
                const temp = {};
                temp.title = typeof column === 'string' ? this.titleCase(column) : (column.label || '');
                temp.dataIndex = column;
                temp.key = column;
                temp.sorter = isSortable ? (a, b) => { a[column] - b[column]; } : false;
                temp.exportable = true;
                temp.sortDirections = ['ascend', 'descend'];
                if (
                    overrideColumnWidths &&
                    overrideColumnWidths.length &&
                    overrideColumnWidths.map(ocw => ocw.key).includes(column)
                ) {
                    const match = overrideColumnWidths.filter(ocw => ocw.key === column)[0]?.width;
                    if (match) {
                        temp.width = match;
                    }
                }

                if (exportableColumns.length > 0 && !exportableColumns.includes(column)) {
                    temp.exportable = false;
                }

                if (filter) {
                    if (filterTemplate == '') {
                        temp.filters = filter.options || [];
                    } else {
                        let element = <Input style={{ width: '100%' }} />;
                        if (filter.element == 'select') {
                            element = (
                                <Select
                                    style={{ width: '100%' }}
                                    allowClear={<Erase size={12} />}
                                    showSearch
                                >
                                    {filter.options.map((option, optionKey) => <Select.Option key={`${filter.key}-${(option.value).split(' ').join('-')}-filter`} value={option.value}>{option.text}</Select.Option>)}
                                </Select>
                            );
                        } else if (filter.element == 'radio') {
                            element = (
                                <Radio.Group>
                                    {filter.options.map((option, optionKey) => <Radio key={`${filter.key}-${(option.value).split(' ').join('-')}-filter`} value={option.value}>{option.text}</Radio>)}
                                </Radio.Group>
                            );
                        } else if (filter.element == 'checkbox') {
                            element = (
                                <Checkbox.Group>
                                    {filter.options.map((option, optionKey) => <Checkbox key={`${filter.key}-${(option.value).split(' ').join('-')}-filter`} value={option.value}>{option.text}</Checkbox>)}
                                </Checkbox.Group>
                            );
                        } else if (filter.element == 'date') {
                            element = (
                                <DatePicker
                                    allowClear={false}
                                    suffixIcon={<Calendar />}
                                />
                            );
                        } else if (filter.element == 'date-range') {
                            element = <RangePicker />;
                        }
                        const replacePlaceholders = (domNode) => {
                            findAndReplace(/{{label}}/g, filter.key, domNode);
                            findAndReplace(/{{element}}/g, element, domNode);
                            return domNode;
                        };
                        const parsedFilterTemplate = parse(filterTemplate, { replace: replacePlaceholders });
                        customFilterTemplate.push(<Fragment key={`fragment-${columnIndex}`}>{parsedFilterTemplate}</Fragment>);
                    }
                }

                return temp;
            });
        return {
            columns,
            customFilterTemplate,
        };
    };

    onRowClick = (e, ctrlClick = false) => {
        const { onRowClick, data } = this.props;

        if (onRowClick) {
            onRowClick(e);
        } else {
            // If no onRowClick is defined in props then we resort to default action which is defined in each grid as actions
            const { columns } = this.getColumnsAndFilter(true);
            if (columns && columns.length && columns.filter((c) => c.title.toLowerCase() === 'actions').length) {
                if (e?.actions?.props?.onClick) {
                    e.actions.props.onClick();
                    return;
                }

                let href = e?.actions?.props?.to;
                if (!href) {
                    if (Array.isArray(e?.actions?.props?.children)) {
                        const foundChild = e.actions.props.children.find((child) => child.props?.to || child.props?.href);
                        href = foundChild?.props?.to ?? foundChild?.props?.href;
                    } else if (typeof e.actions.props.children === 'object') {
                        href = e.actions.props.children?.props?.to ?? e.actions.props.children?.props?.href;
                    }
                }
                if (href) {
                    href = href?.pathname ? href?.pathname : href;
                    ctrlClick ? window.open(href, '_blank') : history.push(href);
                }
            }
        }
    };

    render() {
        const {
            data,
            pagination,
            onChange,
            filterTemplate,
            filterOnChange,
            onRowClick,
            disablePagination,
            showExportButton,
            exportButtonClassName,
            expandedRowRender,
            scroll,
        } = this.props;

        const { columns, customFilterTemplate } = this.getColumnsAndFilter();

        return (
            <>
                <Row>
                    <Col span={24}>
                        {customFilterTemplate}
                    </Col>
                </Row>
                {showExportButton
                    ? (
                        <Row>
                            <Col
                                span={24}
                                style={{ textAlign: 'right' }}
                            >
                                <Button
                                    type='primary'
                                    className='export-dropdown-button'
                                    onClick={this.exportCsv}
                                >
                                    Export
                                </Button>
                            </Col>
                        </Row>
                    )
                    : null}
                <Row>
                    <Col span={24}>
                        <TableView
                            id={this.myTableId}
                            onRowClick={this.onRowClick}
                            onChange={onChange}
                            pagination={disablePagination == true ? false : pagination}
                            dataSource={data}
                            columns={columns}
                            expandedRowRender={expandedRowRender}
                            showSorterTooltip={{
                                target: 'sorter-icon',
                            }}
                            scroll={scroll}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

GridView.defaultProps = {
    disablePagination: false,
    data: [],
    showColumns: [],
    onChange: (pagination, filters, sorter, extra) => {},
    sortableColumns: [],
    exportableColumns: [],
    expandedRowRender: null,
    filterColumns: [],
    filterTemplate: '',
    filterOnChange: () => {},
    pagination: {
        total: 0,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        position: 'bottom',
        showSizeChanger: false,
        showTotalSummary: false,
        itemRender: (current, type, originalElement) => {},
        onChange: (page, pageSize) => {},
        onShowSizeChange: (current, size) => {},
        onRowClick: (record, rowIndex) => {},
    },
    showExportButton: false,
    exportFileName: 'data-export',
    exportButtonClassName: '',
    hiddenColumns: [],
};

GridView.propTypes = {
    disablePagination: PropTypes.bool,
    data: PropTypes.array,
    showColumns: PropTypes.array,
    onChange: PropTypes.func,
    sortableColumns: PropTypes.array,
    exportableColumns: PropTypes.array,
    expandedRowRender: PropTypes.func,
    filterColumns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['text', 'select', 'radio', 'checkbox']),
            options: PropTypes.array,
        }),
    ),
    filterTemplate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    filterOnChange: PropTypes.func,
    pagination: PropTypes.shape({
        pageSize: PropTypes.number,
        total: PropTypes.number.isRequired,
        pageSizeOptions: PropTypes.array,
        position: PropTypes.oneOf(['bottom', 'top']),
        itemRender: PropTypes.func,
        showSizeChanger: PropTypes.bool,
        showTotalSummary: PropTypes.bool,
        onChange: PropTypes.func,
        onShowSizeChange: PropTypes.func,
    }),
    onRowClick: PropTypes.func,
    showExportButton: PropTypes.bool,
    exportFileName: PropTypes.string,
    exportButtonClassName: PropTypes.string,
    pdfOptions: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        pageSize: PropTypes.oneOf(['a4']),
        orientation: PropTypes.oneOf(['portrait', 'landscape']),
    }),
    hiddenColumns: PropTypes.array,
};

export default GridView;
