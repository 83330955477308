import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import {
    Button, Checkbox, Col, Input, Radio, Row, Select,
    TimePicker
} from 'antd';
import { Erase } from '@carbon/icons-react';
import { isEmpty } from 'underscore';
import moment from 'moment';

class GeoFence extends Component {
    constructor(props) {
        super(props);
        this.weekdays = moment.weekdays();
    }
    
    componentDidUpdate(prevProps) {
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const {
            divisions, assets, form, initializeValues,
        } = this.props;

        const { getFieldDecorator } = form;
        const initialDaysActive = this.weekdays.reduce((carry, day) => {
            if (initializeValues?.days_active?.[day] == true) {
                carry.push(day);
            }
            return carry;
        }, []);
        let initialDivisions = [];
        let initialAssets = [];
        if (!isEmpty(initializeValues?.divisions)) {
            initialDivisions = initializeValues.divisions.map((division) => division.id); 
        }

        if (!isEmpty(initializeValues?.assets)) {
            initialAssets = initializeValues.assets.map((asset) => asset.id);
        }

        return (
            <>
                <h5 className="h-5">Edit Geo-Fence</h5>
                <div style={{ textAlign: 'left', alignItems: 'left', width: '100%' }}>
                    <Form onSubmit={this.submitForm}>
                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <span className='modal-label'>Name</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <Form.Item label={null}>
                                    {getFieldDecorator('trigger_name', {
                                        initialValue: initializeValues && initializeValues.name ? initializeValues.name : null,
                                    })(
                                        <Input type="text" placeholder="Enter Geo-fence Name" />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <span className='modal-label'>Emails</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <Form.Item label="">
                                    {getFieldDecorator('emails', {
                                        initialValue: initializeValues?.emails?.split(',').join(', ') || '',
                                        rules: [
                                            {
                                                pattern: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/,
                                                message: "Input contains an invalid email",
                                            }
                                        ]
                                    })(
                                        <Input.TextArea 
                                            rows={3} 
                                            style={{ height: '90px' }} 
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <span className='modal-label'>Alerts will be sent</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={1} />
                            <Col span={23}>
                                <Form.Item 
                                    label={null} 
                                    style={{ marginBottom: '8px' }}
                                >
                                    {getFieldDecorator('days_active', {
                                        initialValue: initialDaysActive,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select at least one day',
                                            },
                                        ]
                                    })(
                                        <Checkbox.Group style={{ width: '100%' }}>
                                            <Row>
                                                {this.weekdays.map(day => {
                                                    return (
                                                        <Col span={8} key={'active-days-' + day}>
                                                            <Checkbox value={day}>{day}</Checkbox>
                                                        </Col>
                                                    )
                                                })}

                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Form.Item 
                                label={null}
                                style={{ marginBottom: '8px' }}
                            >
                                {getFieldDecorator('active_inside', {
                                    initialValue: initializeValues.active_inside,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select if it should be inside or outside the hours',
                                        },
                                    ],
                                })(
                                    <>
                                        &ensp;&thinsp;
                                        <Radio.Group>
                                            <Radio value={1}>Between</Radio>
                                            <Radio value={0}>Outside Of</Radio>
                                        </Radio.Group>
                                    </>
                                )}
                            </Form.Item>
                            <Form.Item label="" style={{ display: "inline-block", width: "50%", paddingRight: "5px" }}>
                                {getFieldDecorator('time_active_start', {
                                    initialValue: moment(initializeValues.time_active_start, 'HH:mm:ss'),
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select the hours from',
                                        },
                                    ],
                                })(
                                    <TimePicker format='HH:mm:ss' size="large" />
                                )}
                            </Form.Item>
                            <Form.Item label="" style={{ display: "inline-block", width: "50%", paddingLeft: "5px" }}>
                                {getFieldDecorator('time_active_end', {
                                    initialValue: moment(initializeValues.time_active_end, 'HH:mm:ss'),
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select the hours to',
                                        },
                                    ],
                                })(
                                    <TimePicker format='HH:mm:ss' size="large" />
                                )}
                            </Form.Item>
                        </Row>


                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <span className='modal-label'>Affects</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={1} />
                            <Col span={23}>
                                <Form.Item 
                                    label={null}
                                    style={{ marginBottom: '0px' }}
                                >
                                    {getFieldDecorator('filter_by', {
                                        initialValue: initializeValues?.filter_by ?? '',
                                    })(
                                        <Radio.Group>
                                            <Radio value={''}>All assets</Radio>
                                            <Radio value='division'>Selected Divisions</Radio>
                                            <br />
                                            <Radio value='asset'>Selected Assets</Radio>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        {form.getFieldValue('filter_by') == "division"
                            ? (
                                <Row style={{ marginTop: '10px' }}>
                                    <Col span={1} />
                                    <Col span={23}>
                                        <Form.Item label={null} >
                                            {getFieldDecorator('divisions', {
                                                initialValue: initialDivisions,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select divisions!',
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    mode="multiple"
                                                    value={initialDivisions}
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Division"
                                                    filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                                                    allowClear={<Erase size={12} />}
                                                    showSearch
                                                >
                                                    {divisions ? divisions.map((division, divisionIndex) => <Select.Option key={`index-${divisionIndex}`} value={division.id}>{division.name}</Select.Option>) : null}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                            : null}
                        {form.getFieldValue('filter_by') == "asset"
                            ? (
                                <Row style={{ marginTop: '10px' }}>
                                    <Col span={1} />
                                    <Col span={23}>
                                        <Form.Item label={null}>
                                            {getFieldDecorator('assets', {
                                                initialValue: initialAssets,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select assets!',
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    mode="multiple"
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Asset"
                                                    filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                                                    allowClear={<Erase size={12} />}
                                                    showSearch
                                                >
                                                    {assets ? assets.map((asset, assetsIndex) => <Select.Option key={`index-${assetsIndex}`} value={asset.id}>{asset.name}</Select.Option>) : null}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                            : null}
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                htmlType="submit"
                                type="primary"
                            >
                                &ensp;Save&ensp;
                            </Button>
                        </div>
                    </Form>
                </div>
            </>
        );
    }
}

GeoFence.propTypes = {
    divisions: PropTypes.array,
    assets: PropTypes.array,
    initializeValues: PropTypes.object,
};

GeoFence.defaulProps = {
    divisions: [],
    assets: [],
};

const GeoFenceForm = Form.create({ name: 'event_filter_form' })(GeoFence);

export default GeoFenceForm;
