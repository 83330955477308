import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_COMPANY_EVENTS_REQUEST: null,
    GET_COMPANY_EVENTS_SUCCESS: null,
    GET_COMPANY_EVENTS_FAILURE: null,
    RESET_COMPANY_EVENTS: null,

    UPDATE_COMPANY_EVENTS_REQUEST: null,
    UPDATE_COMPANY_EVENTS_SUCCESS: null,
    UPDATE_COMPANY_EVENTS_FAILURE: null,

    GET_EVENTS_REQUEST: null,
    GET_EVENTS_SUCCESS: null,
    GET_EVENTS_FAILURE: null,

    GET_DATA_EVENTS_REQUEST: null,
    GET_DATA_EVENTS_SUCCESS: null,
    GET_DATA_EVENTS_FAILURE: null,

    GET_ALL_TRIGGERS_REQUEST: null,
    GET_ALL_TRIGGERS_SUCCESS: null,
    GET_ALL_TRIGGERS_FAILURE: null,

    GET_USER_DIVISIONS_REQUEST: null,
    GET_USER_DIVISIONS_SUCCESS: null,
    GET_USER_DIVISIONS_FAILURE: null,

    GET_SELECTED_EVENT_TRIGGER_REQUEST: null,
    GET_SELECTED_EVENT_TRIGGER_SUCCESS: null,
    GET_SELECTED_EVENT_TRIGGER_FAILURE: null,

    GET_SELECTED_TRIGGER_EVENTS_REQUEST: null,
    GET_SELECTED_TRIGGER_EVENTS_SUCCESS: null,
    GET_SELECTED_TRIGGER_EVENTS_FAILURE: null,

    DELETE_TRIGGER_REQUEST: null,
    DELETE_TRIGGER_SUCCESS: null,
    DELETE_TRIGGER_FAILURE: null,

    GET_EVENT_INFO_REQUEST: null,
    GET_EVENT_INFO_SUCCESS: null,
    GET_EVENT_INFO_FAILURE: null,

    RESET_EVENT_INFO_REQUEST: null,

    GET_EVENT_INFO_BY_VIDEO_REQUEST: null,
    GET_EVENT_INFO_BY_VIDEO_SUCCESS: null,
    GET_EVENT_INFO_BY_VIDEO_FAILURE: null,

    GET_EVENT_INFO_BY_VIDEO_REFERENCE_REQUEST: null,
    GET_EVENT_INFO_BY_VIDEO_REFERENCE_SUCCESS: null,
    GET_EVENT_INFO_BY_VIDEO_REFERENCE_FAILURE: null,

    SHARE_WEB_REPORT_REQUEST: null,
    SHARE_WEB_REPORT_SUCCESS: null,
    SHARE_WEB_REPORT_FAILURE: null,

    VERIFY_REPORT_CODE_REQUEST: null,
    VERIFY_REPORT_CODE_SUCCESS: null,
    VERIFY_REPORT_CODE_FAILURE: null,

    DOWNLOAD_PDF_REPORT_REQUEST: null,
    DOWNLOAD_PDF_REPORT_SUCCESS: null,
    DOWNLOAD_PDF_REPORT_FAILURE: null,

    UPDATE_TRIGGER_REQUEST: null,
    UPDATE_TRIGGER_SUCCESS: null,
    UPDATE_TRIGGER_FAILURE: null,

    RESET_JOURNEY_INFO_REQUEST: null,
    GET_JOURNEY_INFO_REQUEST: null,
    GET_JOURNEY_INFO_SUCCESS: null,
    GET_JOURNEY_INFO_FAILURE: null,

    RESET_JOURNEYS_INFO_REQUEST: null,
    GET_JOURNEYS_INFO_REQUEST: null,
    GET_JOURNEYS_INFO_SUCCESS: null,
    GET_JOURNEYS_INFO_FAILURE: null,

    RESET_JOURNEYS_TO_REQUEST: null,
    SET_JOURNEYS_TO_REQUEST: null,

    GET_EVENTS_CSV_REQUEST: null,
    GET_EVENTS_CSV_SUCCESS: null,
    GET_EVENTS_CSV_FAILURE: null,

    RENEW_SHARED_REPORT_REQUEST: null,
    RENEW_SHARED_REPORT_SUCCESS: null,
    RENEW_SHARED_REPORT_FAILURE: null,

    SAVE_EVENTS_SEARCH_PARAMS_REQUEST: null,
    RESET_UPDATE_MAP_EVENT_DATA_FLAG_REQUEST: null,
    SET_SELECTED_TRIGGER_FETCHED: null,

    SAVE_DATA_EVENTS_SEARCH_PARAMS_REQUEST: null,

    GET_SIDE_EVENTS_REQUEST: null,
    GET_SIDE_EVENTS_SUCCESS: null,
    GET_SIDE_EVENTS_FAILURE: null,

    SET_EVENT_DEVICE_OFFLINE: null,
});

export const {
    getCompanyEventsRequest,
    getCompanyEventsSuccess,
    getCompanyEventsFailure,
    resetCompanyEvents,
} = createActions(
    constants.GET_COMPANY_EVENTS_REQUEST,
    constants.GET_COMPANY_EVENTS_SUCCESS,
    constants.GET_COMPANY_EVENTS_FAILURE,
    constants.RESET_COMPANY_EVENTS,
);

export const {
    updateCompanyEventsRequest,
    updateCompanyEventsSuccess,
    updateCompanyEventsFailure,
} = createActions(
    constants.UPDATE_COMPANY_EVENTS_REQUEST,
    constants.UPDATE_COMPANY_EVENTS_SUCCESS,
    constants.UPDATE_COMPANY_EVENTS_FAILURE,
);

export const {
    getEventsRequest,
    getEventsSuccess,
    getEventsFailure,
} = createActions(
    constants.GET_EVENTS_REQUEST,
    constants.GET_EVENTS_SUCCESS,
    constants.GET_EVENTS_FAILURE,
);

export const {
    getDataEventsRequest,
    getDataEventsSuccess,
    getDataEventsFailure,
} = createActions(
    constants.GET_DATA_EVENTS_REQUEST,
    constants.GET_DATA_EVENTS_SUCCESS,
    constants.GET_DATA_EVENTS_FAILURE,
);

export const {
    getAllTriggersRequest,
    getAllTriggersSuccess,
    getAllTriggersFailure,
} = createActions(
    constants.GET_ALL_TRIGGERS_REQUEST,
    constants.GET_ALL_TRIGGERS_SUCCESS,
    constants.GET_ALL_TRIGGERS_FAILURE,
);

export const {
    getUserDivisionsRequest,
    getUserDivisionsSuccess,
    getUserDivisionsFailure,
} = createActions(
    constants.GET_USER_DIVISIONS_REQUEST,
    constants.GET_USER_DIVISIONS_SUCCESS,
    constants.GET_USER_DIVISIONS_FAILURE,
);

export const {
    getSelectedEventTriggerRequest,
    getSelectedEventTriggerSuccess,
    getSelectedEventTriggerFailure,
} = createActions(
    constants.GET_SELECTED_EVENT_TRIGGER_REQUEST,
    constants.GET_SELECTED_EVENT_TRIGGER_SUCCESS,
    constants.GET_SELECTED_EVENT_TRIGGER_FAILURE,
);

export const {
    getSelectedTriggerEventsRequest,
    getSelectedTriggerEventsSuccess,
    getSelectedTriggerEventsFailure,
} = createActions(
    constants.GET_SELECTED_TRIGGER_EVENTS_REQUEST,
    constants.GET_SELECTED_TRIGGER_EVENTS_SUCCESS,
    constants.GET_SELECTED_TRIGGER_EVENTS_FAILURE,
);

export const {
    deleteTriggerRequest,
    deleteTriggerSuccess,
    deleteTriggerFailure,
} = createActions(
    constants.DELETE_TRIGGER_REQUEST,
    constants.DELETE_TRIGGER_SUCCESS,
    constants.DELETE_TRIGGER_FAILURE,
);

export const {
    getEventInfoRequest,
    getEventInfoSuccess,
    getEventInfoFailure,
} = createActions(
    constants.GET_EVENT_INFO_REQUEST,
    constants.GET_EVENT_INFO_SUCCESS,
    constants.GET_EVENT_INFO_FAILURE,
);

export const {
    resetEventInfoRequest,
    getEventInfoByVideoRequest,
    getEventInfoByVideoSuccess,
    getEventInfoByVideoFailure,
} = createActions(
    constants.RESET_EVENT_INFO_REQUEST,
    constants.GET_EVENT_INFO_BY_VIDEO_REQUEST,
    constants.GET_EVENT_INFO_BY_VIDEO_SUCCESS,
    constants.GET_EVENT_INFO_BY_VIDEO_FAILURE,
);

export const {
    getEventInfoByVideoReferenceRequest,
    getEventInfoByVideoReferenceSuccess,
    getEventInfoByVideoReferenceFailure,
} = createActions(
    constants.GET_EVENT_INFO_BY_VIDEO_REFERENCE_REQUEST,
    constants.GET_EVENT_INFO_BY_VIDEO_REFERENCE_SUCCESS,
    constants.GET_EVENT_INFO_BY_VIDEO_REFERENCE_FAILURE,
);

export const {
    shareWebReportRequest,
    shareWebReportSuccess,
    shareWebReportFailure,
} = createActions(
    constants.SHARE_WEB_REPORT_REQUEST,
    constants.SHARE_WEB_REPORT_SUCCESS,
    constants.SHARE_WEB_REPORT_FAILURE,
);

export const {
    verifyReportCodeRequest,
    verifyReportCodeSuccess,
    verifyReportCodeFailure,
} = createActions(
    constants.VERIFY_REPORT_CODE_REQUEST,
    constants.VERIFY_REPORT_CODE_SUCCESS,
    constants.VERIFY_REPORT_CODE_FAILURE,
);

export const {
    downloadPdfReportRequest,
    downloadPdfReportSuccess,
    downloadPdfReportFailure,
} = createActions(
    constants.DOWNLOAD_PDF_REPORT_REQUEST,
    constants.DOWNLOAD_PDF_REPORT_SUCCESS,
    constants.DOWNLOAD_PDF_REPORT_FAILURE,
);

export const {
    updateTriggerRequest,
    updateTriggerSuccess,
    updateTriggerFailure,
} = createActions(
    constants.UPDATE_TRIGGER_REQUEST,
    constants.UPDATE_TRIGGER_SUCCESS,
    constants.UPDATE_TRIGGER_FAILURE,
);

export const {
    resetJourneyInfoRequest,
    getJourneyInfoRequest,
    getJourneyInfoSuccess,
    getJourneyInfoFailure,
} = createActions(
    constants.RESET_JOURNEY_INFO_REQUEST,
    constants.GET_JOURNEY_INFO_REQUEST,
    constants.GET_JOURNEY_INFO_SUCCESS,
    constants.GET_JOURNEY_INFO_FAILURE,
);

export const {
    resetJourneysInfoRequest,
    getJourneysInfoRequest,
    getJourneysInfoSuccess,
    getJourneysInfoFailure,
} = createActions(
    constants.RESET_JOURNEYS_INFO_REQUEST,
    constants.GET_JOURNEYS_INFO_REQUEST,
    constants.GET_JOURNEYS_INFO_SUCCESS,
    constants.GET_JOURNEYS_INFO_FAILURE,
);

export const {
    resetJourneysToRequest,
    setJourneysToRequest,
} = createActions(
    constants.RESET_JOURNEYS_TO_REQUEST,
    constants.SET_JOURNEYS_TO_REQUEST,
);

export const {
    getEventsCsvRequest,
    getEventsCsvSuccess,
    getEventsCsvFailure,
} = createActions(
    constants.GET_EVENTS_CSV_REQUEST,
    constants.GET_EVENTS_CSV_SUCCESS,
    constants.GET_EVENTS_CSV_FAILURE,
);

export const {
    renewSharedReportRequest,
    renewSharedReportSuccess,
    renewSharedReportFailure,
} = createActions(
    constants.RENEW_SHARED_REPORT_REQUEST,
    constants.RENEW_SHARED_REPORT_SUCCESS,
    constants.RENEW_SHARED_REPORT_FAILURE,
);

export const {
    saveEventsSearchParamsRequest,
    resetUpdateMapEventDataFlagRequest,
    setSelectedTriggerFetched,
} = createActions(
    constants.SAVE_EVENTS_SEARCH_PARAMS_REQUEST,
    constants.RESET_UPDATE_MAP_EVENT_DATA_FLAG_REQUEST,
    constants.SET_SELECTED_TRIGGER_FETCHED,
);

export const {
    saveDataEventsSearchParamsRequest,
} = createActions(
    constants.SAVE_DATA_EVENTS_SEARCH_PARAMS_REQUEST,
);

export const {
    getSideEventsRequest,
    getSideEventsSuccess,
    getSideEventsFailure,
} = createActions(
    constants.GET_SIDE_EVENTS_REQUEST,
    constants.GET_SIDE_EVENTS_SUCCESS,
    constants.GET_SIDE_EVENTS_FAILURE,
);

export const {
    setEventDeviceOffline,
} = createActions(
    constants.SET_EVENT_DEVICE_OFFLINE,
);
