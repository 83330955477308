import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Spin, Button, Row, Col, Popconfirm,
} from 'antd';
import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';
import CreateManagementAlertForm from '../forms/createManagementAlertForm';
import * as driverActions from '../../../core/driver/driverActions';

class DriverManagementAlertsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateAlertForm: false,
            serviceAlert: {
                id: null,
                name: null,
                description: null,
                service_date: null,
                frequency: null,
                frequency_desc: null,
                asset_id: null,
                user_id: null,
                alert_type: 1,
            },
        };
    }

    componentDidMount() {
        const {
            actions, setLoadedState, serviceAlertsTabLoaded, driverId,
        } = this.props;
        if (!serviceAlertsTabLoaded) {
            actions.getDriverServiceAlertsRequest({
                driverId,
                alert_type: 1,
                page: 1,
            });
            setLoadedState();
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange, serviceAlertsPagination } = this.props;

        if (pagination && pagination.current === serviceAlertsPagination.currentPage) {
            onTableChange(pagination, filters, sorter, extra);
        }
    };

    createServiceAlert = (values) => {
        const { actions, driverId } = this.props;
        const { serviceAlert } = this.state;
        if (values.service_date) {
            values.service_date = values.service_date.format('YYYY-MM-DD');
        }

        actions.createDriverServiceAlertRequest({
            driverId,
            alertId: serviceAlert.id || 0,
            ...values,
        });

        this.setState({ showCreateAlertForm: false });
    };

    deleteServiceAlert = (id) => {
        const { actions, driverId } = this.props;
        actions.deleteDriverServiceAlertRequest({
            alertId: id,
            driverId,
        });
        this.setState({ showCreateAlertForm: false });
    };

    clearForm = () => {
        this.setState({
            showCreateAlertForm: false,
            serviceAlert: {
                id: null,
                name: null,
                description: null,
                service_date: null,
                frequency: null,
                frequency_desc: null,
                asset_id: null,
                user_id: null,
                alert_type: 0,
            },
        });
    };

    createForm = () => {
        this.setState({
            showCreateAlertForm: false,
            serviceAlert: {
                id: null,
                name: null,
                description: null,
                service_date: null,
                frequency: null,
                frequency_desc: null,
                asset_id: null,
                user_id: null,
                alert_type: 0,
            },
        });

        setTimeout(() => this.setState({
            showCreateAlertForm: true,
        }), 1);
    };

    render() {
        const {
            serviceAlerts, serviceAlertsPagination, isFetching, companyUsers, assetList,
        } = this.props;
        const { showCreateAlertForm, serviceAlert } = this.state;
        const listAssetServiceAlerts = serviceAlerts.map((assetAlert, assetAlertIndex) => {
            // const location = getAddressFromLatLong(journey.latitude, event.longitude, 'street_address');
            const circleColour = assetAlert.events > 0 ? 'red' : 'green';

            const tempAssetAlert = {};
            tempAssetAlert.alert_name = assetAlert.name;
            tempAssetAlert.alert_description = assetAlert.description;
            tempAssetAlert.next_alert = moment(assetAlert.service_date, 'YYYY-MM-DD').add(1, 'years').format('DD/MM/YYYY');
            tempAssetAlert.frequency = assetAlert.frequency_desc;
            const tAsset = assetList.find((a) => a.id == assetAlert.asset_id);
            // tempAssetAlert.asset = (tAsset && tAsset.name) || '';
            tempAssetAlert.actions = (
                <div>
                    <a
                        href="#"
                        className="btn btn-empty ml-2"
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                                showCreateAlertForm: true,
                                serviceAlert: {
                                    id: assetAlert.id,
                                    name: assetAlert.name,
                                    description: assetAlert.description,
                                    service_date: assetAlert.service_date,
                                    frequency: assetAlert.frequency,
                                    frequency_desc: assetAlert.frequency_desc,
                                    asset_id: assetAlert.asset_id,
                                    user_id: assetAlert.user_id,
                                    alert_type: assetAlert.alert_type,
                                },
                            });
                        }}>
                        <Icon name="pencil" />
                    </a>
                    &ensp;
                    <Popconfirm
                        onConfirm={() => this.deleteServiceAlert(assetAlert.id)}
                        title="Are you sure to delete this alert？"
                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>
                        <a href="#" className="btn btn-empty ml-2">
                            <Icon name="close" />
                        </a>
                    </Popconfirm>
                </div>
            );
            // tempEvent.asset_reg = event.registration;
            return tempAssetAlert;
        });

        let spinning = false;
        for (let i = 0; i < isFetching.length; i++) {
            const item = isFetching[i];
            if (item.GET_DRIVER_SERVICE_ALERTS) {
                spinning = true;
                break;
            }
        }

        return (
            <Spin spinning={spinning}>
                <Row>
                    <Col span={24}>
                        <Button 
                            style={{ float: 'right' }} 
                            type="primary" 
                            onClick={this.createForm}
                        >
                            add alert
                        </Button>
                    </Col>
                </Row>
                {showCreateAlertForm && (
                    <CreateManagementAlertForm 
                        serviceAlert={serviceAlert} 
                        clearForm={this.clearForm} 
                        onSubmit={this.createServiceAlert} 
                    />
                )}
                <br />
                <GridView
                    // filterTemplate='<div><div style="display: inline-block; width: 45%">{{label}}</div><div style="display: inline-block; width: 45%">{{element}}</div><div></div></div>'
                    data={listAssetServiceAlerts}
                    onChange={this.onTableChange}
                    sortableColumns={['start_time', 'end_time', 'time_driven', 'driver', 'area_driven', 'score', 'events']}
                    pagination={{
                        total: parseInt(serviceAlertsPagination.totalRecords, 10),
                        showSizeChanger: false,
                        pageSize: parseInt(serviceAlertsPagination.perPageCount, 10),
                        onChange: this.onPageChange,
                    }} />
                
            </Spin>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        serviceAlerts: state.driver.serviceAlerts,
        serviceAlertsPagination: state.driver.serviceAlertsPagination,
        isFetching: state.driver.isFetching,
        assetList: state.asset.assetList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DriverManagementAlertsTab);
