/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { Erase } from '@carbon/icons-react';

// import '@ant-design/compatible/assets/index.css';


import {
    Button,
    Row,
    Col,
    Tabs,
    Popconfirm,
    Popover,
    Modal,
    Select,
    Empty,
    Input,
    Spin,
} from 'antd';
import { ExclamationCircleOutlined, TagOutlined } from '@ant-design/icons';
import { filter, isEmpty } from 'underscore';
import moment from 'moment';

import CreateDriverForm from './forms/createDriverForm';
import DriverJourneyHistory from './tabs/driverJourneyHistory';
import DriverJourneyHistoryFilter from './forms/driverJourneyHistoryFilter';
import DriverEventHistoryTab from './tabs/driverEventHistory';
import DriverEventHistory from './forms/driverEventHistoryFilter';
import DriverManagementAlertsTab from './tabs/driverManagementAlerts';
import DriverServiceAlertsFilter from './forms/driverServiceAlertsFilter';
import DriverPerformance from './tabs/driverPerformance';
import Layout from '../../components/layout/Layout';
import Avatar from '../../components/elements/Avatar';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import Loading from '../../components/elements/Loading';
import history from '../../core/utils/history';
import CustomEmpty from '../../components/CustomEmpty';

const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;

export default function () {
    const {
        driver,
        asset,
        match,
        tagStatus,
        user,
    } = this.props;
    const {
        selectedAsset,
        currentTabKey,
        journeyHistoryTabLoaded,
        eventsHistoryTabLoaded,
        serviceAlertsTabLoaded,
        deleteDataConfirmButtonVisible,
        isDeleteDataModalVisible,
        displayFilter,
    } = this.state;
    const { driverId } = match.params;

    let filterContent = null;
    let journeyHistoryTabContent = null;
    let eventHistoryTabContent = null;
    let videoHistoryTabContent = null;
    let serviceAlertsTabContent = null;

    if (currentTabKey == '1') {
        filterContent = <DriverJourneyHistoryFilter driverId={driverId} onSubmit={this.onJourneyHistoryTabFilterChange} />;
        journeyHistoryTabContent = (
            <DriverJourneyHistory
                driverId={driverId}
                onPageChange={this.onJourneyHistoryTabPageChange}
                onTableChange={this.onJourneyHistoryTabSortChange}
                setLoadedState={this.setJourneyHistoryTabLoaded}
                journeyHistoryTabLoaded={journeyHistoryTabLoaded} 
                journeyHistoryFirstLoad={this.state.journeyHistoryFirstLoad} />
        );
        eventHistoryTabContent = null;
        videoHistoryTabContent = null;
        serviceAlertsTabContent = null;
    } else if (currentTabKey == '2') {
        filterContent = <DriverEventHistory onSubmit={this.onEventsHistoryTabFilterChange} />;
        journeyHistoryTabContent = null;
        eventHistoryTabContent = (
            <DriverEventHistoryTab
                driverId={driverId}
                onPageChange={this.onEventsHistoryTabPageChange}
                onTableChange={this.onEventsHistoryTabSortChange}
                setLoadedState={this.setEventsHistoryTabLoaded}
                eventsHistoryTabLoaded={eventsHistoryTabLoaded}
                eventHistoryFirstLoad={this.state.eventHistoryFirstLoad} />
        );
        videoHistoryTabContent = null;
        serviceAlertsTabContent = null;
    } else if (currentTabKey == '4') {
        filterContent = <DriverServiceAlertsFilter onSubmit={this.onServiceAlertsTabFilterChange} />;
        journeyHistoryTabContent = null;
        eventHistoryTabContent = null;
        videoHistoryTabContent = null;
        serviceAlertsTabContent = (
            <DriverManagementAlertsTab
                driverId={driverId}
                onPageChange={this.onServiceAlertsTabPageChange}
                onTableChange={this.onServiceAlertsTabSortChange}
                setLoadedState={this.setServiceAlertsTabLoaded}
                serviceAlertsTabLoaded={serviceAlertsTabLoaded} />
        );
    }
    const driverTabLabel = (driver && driver.singleDriver && driver.singleDriver.profile && driver.singleDriver.profile.full_name) || '[not-set]';

    if (isEmpty(driver.singleDriver)) {
        return (
            <Layout
                className="assets"
                title={<div>Drivers</div>}
                headerTitle={<div>Drivers</div>}
                showFooter={false}>
                <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                    <br />
                    <br />
                    <CustomEmpty />
                </div>
            </Layout>
        );
    }

    const isFetching = driver?.isFetching || [];
    let spinning = false;
    isFetching.forEach((item) => {
        const actionKeys = ['GET_SINGLE_DRIVER', 'TAG_LIMIT_REACHED_REQUEST'];
        for (let i = 0; i < actionKeys.length; i++) {
            const key = actionKeys[i];
            if (item[key]) {
                spinning = true;
                break;
            }
        }
    });

    let assetName = 'Not Assigned';
    if (driver.singleDriver.asset) {
        if (driver.singleDriver.asset.registration) {
            assetName = driver.singleDriver.asset.registration;
        } else {
            assetName = driver.singleDriver.asset.name;
        }
    }

    return (
        (<Layout
            data-test="pages-drivers-view"
            className="assets"
            title={<div>DriverView</div>}
            headerTitle={<div>DriverView Page</div>}
            showFooter={false}>
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                {displayFilter && filterContent ? (
                    <FilterSidebar displayFilter={displayFilter}>
                        {filterContent}
                    </FilterSidebar>
                ) : (
                    <>
                        <br />
                        <br />
                    </>
                )}
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <Spin spinning={spinning}>
                                    <div 
                                        className="section__action-bar justify-center"
                                        style={{ marginBottom: '32px' }}
                                    >
                                        <span style={{ marginRight: 'auto' }}>
                                            {user.permissions.adding_drivers === true ? (
                                                <>
                                                    <Button
                                                        type="primary"
                                                        className="btn"
                                                        style={{ 
                                                            marginRight: '16px',
                                                        }}
                                                        onClick={this.toggleModalAssign}
                                                    >
                                                        <span>Assign Asset</span>
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        className="btn"
                                                        onClick={this.toggleModalEdit}
                                                    >
                                                        <span>Edit Driver</span>
                                                    </Button>
                                                </>
                                            ) : null}
                                        </span>
                                        <span className='asset-name-label'>
                                            {driver && driver.singleDriver && driver.singleDriver.profile
                                                ? driver.singleDriver.profile.full_name
                                                : null}
                                        </span>
                                        <span style={{ marginLeft: 'auto' }}>
                                            <div style={{ display: 'inline-block', width: '160px', height: '1px' }} />
                                            {user.role === 'admin' || user.role === 'super-admin' ? (
                                                <Button 
                                                    className="btn btn-bordered" 
                                                    style={{ 
                                                        width:'120px',
                                                        marginLeft: '16px',
                                                    }} 
                                                    onClick={this.showModal}
                                                >
                                                    <span>Delete Data</span>
                                                </Button>
                                            ) : null}
                                            <Modal
                                                icon={null}
                                                title="Are you sure?"
                                                open={isDeleteDataModalVisible}
                                                onOk={this.handleOk}
                                                onCancel={this.handleCancel}
                                                okText="Delete"
                                                okButtonProps={{ disabled: !deleteDataConfirmButtonVisible }}
                                            >
                                                <br />
                                                <p style={{ 
                                                    fontSize: '12px', 
                                                    textAlign: 'center' 
                                                }}>
                                                    If you're sure then type 'delete' into the field below and click confirm.
                                                </p>
                                                <br />
                                                <Input 
                                                    onChange={(e) => this.saveDeleteText(e.target.value)} 
                                                    placeholder="Type 'delete'" 
                                                    style={{ marginBottom: '16px' }}
                                                />
                                            </Modal>
                                        </span>
                                    </div>
                                    <Row
                                        gutter={40}
                                        type="flex"
                                        justify="space-between"
                                        className="data-row">
                                        <Col xl={6}>
                                            {driver && driver.singleDriver && driver.singleDriver.profile
                                                ? (
                                                    <ul 
                                                        className="list" 
                                                        style={{ marginLeft: '16px' }}
                                                    >
                                                        <li className="list__item">
                                                            <div className="list__item-name">
                                                                Name:
                                                            </div>
                                                            <div className="list__item-value">
                                                                {driver.singleDriver.profile.full_name}
                                                            </div>
                                                        </li>
                                                        <li className="list__item">
                                                            <div className="list__item-name">
                                                                Driver Licence Type:
                                                            </div>
                                                            <div className="list__item-value">
                                                                {driver.singleDriver.licenceType && driver.singleDriver.licenceType.title ? driver.singleDriver.licenceType.title : '[not-set]'}
                                                            </div>
                                                        </li>
                                                        {driver && driver.singleDriver && driver.singleDriver.division
                                                            ? (
                                                                <li className="list__item">
                                                                    <div className="list__item-name">
                                                                        Division:
                                                                    </div>
                                                                    <div className="list__item-value">
                                                                        {driver.singleDriver.division.name}
                                                                    </div>
                                                                </li>
                                                            )
                                                            : null}

                                                        <li className="list__item">
                                                            <div className="list__item-name">
                                                                Daily Distance Covered:
                                                            </div>
                                                            <div className="list__item-value">
                                                                {driver.singleDriver.dailyMileage}
                                                            </div>
                                                        </li>
                                                        <li className="list__item">
                                                            <div className="list__item-name">
                                                                Total Distance Covered:
                                                            </div>
                                                            <div className="list__item-value">
                                                                {driver.singleDriver.totalMileage}
                                                            </div>
                                                        </li>
                                                        <li className="list__item">
                                                            <div className="list__item-name">
                                                                Total Hours:
                                                            </div>
                                                            <div className="list__item-value">
                                                                {driver.singleDriver.totalHours} Hours
                                                            </div>
                                                        </li>
                                                        <li className="list__item">
                                                            <div className="list__item-name">
                                                                Driver Status:
                                                            </div>
                                                            <div className="list__item-value" style={{ textTransform: 'capitalize' }}>
                                                                {driver.singleDriver.profile.driver_status}
                                                            </div>
                                                        </li>
                                                        <li className="list__item">
                                                            <div className="list__item-name">
                                                                Driver Availability:
                                                            </div>
                                                            <div className="list__item-value">
                                                                {driver.singleDriver.profile.driver_availability == 1 ? 'Available' : 'Not available'}
                                                            </div>
                                                        </li>
                                                        <li className="list__item">
                                                            <div className="list__item-name">
                                                                Driving Operating Hours:
                                                            </div>
                                                            <div className="list__item-value">
                                                                From {driver.singleDriver.profile.operation_start_time ? moment(driver.singleDriver.profile.operation_start_time, 'HH:mm:ss').format('HH:mm') : '[not-set]'}{' '}
                                                                till {driver.singleDriver.profile.operation_end_time ? moment(driver.singleDriver.profile.operation_end_time, 'HH:mm:ss').format('HH:mm') : '[not-set]'}
                                                            </div>
                                                        </li>
                                                        <li className="list__item">
                                                            <div className="list__item-name">
                                                                Registration:
                                                            </div>
                                                            <div className="list__item-value" style={{ textTransform: 'capitalize' }}>
                                                                {assetName}
                                                            </div>
                                                        </li>
                                                        {!isEmpty(driver.singleDriver.profile.notes) ? (
                                                            <li className="list__item">
                                                                <div className="list__item-name">
                                                                    Notes:
                                                                </div>
                                                                <div className="list__item-value">
                                                                    {driver.singleDriver.profile.notes}
                                                                </div>
                                                            </li>
                                                        )
                                                            : null}
                                                    </ul>
                                                )
                                                : null}
                                        </Col>
                                        <Col xl={11}>
                                            <div className="actions">
                                                <Row
                                                    type="flex"
                                                    gutter={40}
                                                    justify="space-between">
                                                    <Col>
                                                        {driver && driver.singleDriver && driver.singleDriver.profile
                                                            ? <Avatar pictureUrl={driver.singleDriver.profile.profilePictureFull} onChange={(data) => this.updateDriverPicture(data)} />
                                                            : null}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Tabs
                                        onTabClick={(key) => this.setState({ currentTabKey: key })}
                                        defaultActiveKey="1"
                                        animated={false}
                                        className="tabs-restyled2">
                                        <TabPane tab="journey history" key="1">
                                            <div className="section__body">
                                                {journeyHistoryTabContent}
                                            </div>
                                        </TabPane>
                                        <TabPane tab="event history" key="2">
                                            <div className="section__body">
                                                {eventHistoryTabContent}
                                            </div>
                                        </TabPane>
                                        {/* <TabPane tab="video history" key="3">
                                                <div className="section__body">

                                                </div>
                                            </TabPane> */}
                                        <TabPane tab="management alerts" key="4">
                                            <div className="section__body">
                                                {serviceAlertsTabContent}
                                            </div>
                                        </TabPane>
                                        <TabPane tab="driver performance" key="5">
                                            <div className="section__body">
                                                <DriverPerformance />
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </Spin>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Modal
                open={this.state.modalAssignToAssets}
                onCancel={this.toggleModalAssign}
                footer={false}
                width={388}>
                <div className="d-flex dir-column align-center">
                    <h4 
                        className="h-4" 
                        style={{ textTransform: 'none' }}
                    >
                        Assign to Asset
                    </h4>
                    <Form layout="vertical" className="mt-4">
                        <FormItem>
                            <Select
                                showSearch
                                allowClear={<Erase size={12} />}
                                style={{ width: '100%' }}
                                placeholder="Select Asset"
                                optionFilterProp="children"
                                defaultValue={driver.singleDriver.asset ? driver.singleDriver.asset.id : null}
                                onChange={(data) => this.selectAsset(data)}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {!isEmpty(asset.assetList)
                                    ? asset.assetList.map((item) => (
                                        <Option key={item.id} value={item.id}>{`${item.name} ${item.registration}`}</Option>
                                    ))
                                    : null}
                            </Select>
                        </FormItem>
                        <div className="assign-to">
                            <div className="assign-to__item">
                                {driver && driver.singleDriver && driver.singleDriver.profile && driver.singleDriver.profile.profilePictureThumb
                                    ? (
                                        <img
                                            src={driver.singleDriver.profile.profilePictureThumb}
                                            alt="Driver"
                                            className="fitted" />
                                    )
                                    : (
                                        <img
                                            src="https://api.monosnap.com/file/download?id=5rqj7D0AkOhSEpIci6p6ThpJovzpCg"
                                            alt="Driver"
                                            className="fitted" />
                                    )}
                            </div>
                            <div className="assign-to__arrow">
                                <Icon name="right-arrow" />
                            </div>
                            <div className="assign-to__item">
                                {isEmpty(selectedAsset)
                                    ? <h3 style={{ textAlign: 'center' }}>Please select asset</h3>
                                    : (
                                        <>
                                            {selectedAsset && selectedAsset.pictureThumb
                                                ? (
                                                    <img
                                                        src={selectedAsset.pictureThumb}
                                                        alt="Truck"
                                                        className="fitted" />
                                                )
                                                : (
                                                    <img
                                                        src="https://api.monosnap.com/file/download?id=V5zuJ6UXRXjjblUfezXU1YwEJ7tZ9l"
                                                        alt="Truck"
                                                        className="fitted" />
                                                )}
                                        </>
                                    )}
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <Button
                                onClick={() => this.assignAsset()}
                                htmlType="submit"
                                type="primary"
                            >
                                assign to asset
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal
                open={this.state.modalEdit}
                onCancel={this.toggleModalEdit}
                className="with-narrow-padding"
                footer={false}
                width={400}>
                <div style={{ width: '100%' }}>
                    <CreateDriverForm updateDriver={this.updateDriver} />
                </div>
            </Modal>
        </Layout>)
    );
}
