import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FlagFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import DOM from './singleJourneyTab';
import {
    calculateSpeedAndUnit, getMapAssetPopupContent, getMapAssetPopupHead, setMarkerPopoverDetails, setMarkerPopoverToLoading,
} from '../../../core/utils/mapUtils';

import * as eventsActions from '../../../core/events/eventsActions';
import * as deviceActions from '../../../core/device/deviceActions';
import history from '../../../core/utils/history';

class SingleJourneyTab extends Component {
    constructor(props) {
        super(props);
        this.centerMap = {};
        this.googleMapAccess = React.createRef();
        this.state = {
            before_time: 15,
            after_time: 15,
            copySuccess: '',
            sliderValue: 0,
            markerPopover: null,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const {
            actions, match, selectedJourney, journeyId,
        } = this.props;

        actions.resetJourneyInfoRequest();
        actions.getJourneyInfoRequest({ journeyId });
        setMarkerPopoverToLoading(this);
    }

    componentDidUpdate(prevProps) {
        const {
            actions,
            selectedJourney,
            eventJourneyFetched,
            deviceDetailedLocationFetching,
            deviceDetailedLocationInfo,
            journeyId,
            currentTabKey,
            tabKey,
        } = this.props;

        if (prevProps.currentTabKey !== currentTabKey && tabKey === currentTabKey) {
            actions.resetJourneyInfoRequest();
            actions.getJourneyInfoRequest({ journeyId });
            setMarkerPopoverToLoading(this);
        }

        if (prevProps.selectedJourney && selectedJourney) {
            if (prevProps.selectedJourney.startInterval !== selectedJourney.startInterval) {
                this.setState({ sliderValue: selectedJourney.startInterval });
            }
            if (eventJourneyFetched) {
                actions.resetUpdateMapEventDataFlagRequest();
                setTimeout(() => {
                    if (this.googleMapAccess && this.googleMapAccess.current) {
                        this.googleMapAccess.current.setState({ sliderMaxValue: selectedJourney.endInterval });
                        this.googleMapAccess.current.executeAutoZoom();
                    }
                }, 300);
            }
        }

        if (deviceDetailedLocationFetching === false && deviceDetailedLocationInfo !== prevProps.deviceDetailedLocationInfo) {
            setMarkerPopoverDetails(this);
        }
    }

    updateSliderValue = (value) => {
        this.setState({ sliderValue: value });
    };

    markerOnClick = (event) => {
        history.push(`/events/custom-view/${event.event_id}/${event.device_id}`);
    };

    mapDevicePath = () => {
        const { selectedJourney } = this.props;
        let foundFirstEvent = false;
        return selectedJourney && selectedJourney.path ? selectedJourney.path.map((event, eventIndex) => {
            const tempEvent = {};
            tempEvent.time_interval = event.time_interval;
            tempEvent.record_id = event.id;
            tempEvent.lat = event.lat;
            tempEvent.lng = event.lng;
            tempEvent.angle = event.angle;
            tempEvent.speed = event.speed;
            tempEvent.position = 'path';
            tempEvent.title = selectedJourney?.info?.name || null;
            tempEvent.registration = selectedJourney?.info?.registration || null;

            if (eventIndex === 0) {
                tempEvent.position = 'end';
            } else if (eventIndex === (selectedJourney.path).length - 1) {
                tempEvent.position = 'start';
            }
            if ((event.priority === 1 && foundFirstEvent === false) || eventIndex === 0) {
                this.centerMap.lat = parseFloat(event.lat);
                this.centerMap.lng = parseFloat(event.lng);
                if (eventIndex > 0) {
                    foundFirstEvent = true;
                }
            }
            if (event.show_in_events === 1) {
                tempEvent.event_id = event.event_id;
                tempEvent.device_id = event.device_id;
                this.centerMap.lat = parseFloat(event.lat);
                this.centerMap.lng = parseFloat(event.lng);
                const iconStyle = {};
                iconStyle.fontSize = '16px';
                iconStyle.color = '#CF2B28';
                tempEvent.position = 'marker';
                tempEvent.currentMarker = {
                    event_id: event.event_id,
                    device_id: event.device_id,
                };
                let eventTitle = event.event_name;

                if (event.event_type == 253) {
                    if (event.green_driving_type == 0) {
                        eventTitle = 'Eco-friendly driving';
                    } else if (event.green_driving_type == 1) {
                        eventTitle = 'Harsh accelerating';
                    } else if (event.green_driving_type == 2) {
                        eventTitle = 'Harsh braking';
                    } else if (event.green_driving_type == 3) {
                        eventTitle = 'Harsh cornering';
                    }
                }

                if (event.icon && event.icon !== '') {
                    tempEvent.currentMarker.icon = <Tooltip placement="top" title={eventTitle}><LegacyIcon style={iconStyle} theme="filled" type={event.icon} /></Tooltip>;
                } else {
                    tempEvent.currentMarker.icon = <Tooltip placement="top" title={eventTitle}><FlagFilled style={iconStyle} /></Tooltip>;
                }
            }
            return tempEvent;
        })
            : [];
    };

    getAssetCurrentPin = (mappedDevicePath) => {
        const { sliderValue } = this.state;
        const { selectedJourney, user } = this.props;
        const assets = mappedDevicePath.filter((path) => path.time_interval == sliderValue);

        if (assets && assets[0]) {
            const info = selectedJourney && selectedJourney.info;
            const { speed, unit } = calculateSpeedAndUnit(assets[0], user);
            const title = getMapAssetPopupHead(assets[0]);
            const content = getMapAssetPopupContent(this, assets[0], speed, unit, user?.userCompany?.company_image);

            assets[0].arrowIcon = true;
            assets[0].hasEvents = true;
            assets[0].imei = '';
            assets[0].online = 0;
            assets[0].registration = '';
            assets[0].title = info.name;
            assets[0].markers = [];
            assets[0].currentMarker = {};
            assets[0].currentMarker.lat = parseFloat(assets[0].lat);
            assets[0].currentMarker.lng = parseFloat(assets[0].lng);
            assets[0].currentMarker.angle = assets[0].angle;
            assets[0].currentMarker.imei = '';
            assets[0].currentMarker.showArrowIcon = true;
            assets[0].currentMarker.speed = assets[0].speed;
            assets[0].currentMarker.time_interval = assets[0].time_interval;
            assets[0].currentMarker.title = '';
            assets[0].markers[assets[0].time_interval] = {};
            assets[0].markers[assets[0].time_interval].record_id = assets[0].record_id;
            assets[0].markers[assets[0].time_interval].lat = parseFloat(assets[0].lat);
            assets[0].markers[assets[0].time_interval].lng = parseFloat(assets[0].lng);
            assets[0].markers[assets[0].time_interval].angle = assets[0].angle;
            assets[0].markers[assets[0].time_interval].imei = '';
            assets[0].markers[assets[0].time_interval].showArrowIcon = true;
            assets[0].markers[assets[0].time_interval].speed = assets[0].speed;
            assets[0].markers[assets[0].time_interval].time_interval = assets[0].time_interval;
            assets[0].markers[assets[0].time_interval].title = title;
            assets[0].markers[assets[0].time_interval].content = content;
        }
        return assets;
    };

    render() {
        return this.view();
    }
}

SingleJourneyTab.propTypes = {
    actions: PropTypes.object.isRequired,
    deviceDetailedLocationFetching: PropTypes.bool.isRequired,
    deviceDetailedLocationInfo: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedJourney: state.events.selectedJourney,
        eventJourneyFetched: state.events.eventJourneyFetched,
        isFetching: state.events.isFetching,
        journeyBackLink: state.global.journeyBackLink,
        user: state.user,
        deviceDetailedLocationFetching: state.device.deviceDetailedLocationFetching,
        deviceDetailedLocationInfo: state.device.deviceDetailedLocationInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
                ...deviceActions,
            },
            dispatch,
        ),
    };
}
export const SingleJourneyTabTest = SingleJourneyTab;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SingleJourneyTab);
