/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Empty } from 'antd';
import { isEmpty, size } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import CustomEmpty, { CustomEmptyType } from '../../../../../components/CustomEmpty';

class ReportTab extends PureComponent {
    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    render() {
        const {
            ptoReport,
            isFetching,
            toggleFilterSidebar,
        } = this.props;

        const listReports = ptoReport.map((report) => ({
            ...report,
            ...(Array.isArray(report.name) && report.name[0] && {
                name: (
                    <Link to={`/assets/view/${report.name[0]}`} className="btn btn-empty">
                        {report.name[1]}
                    </Link>
                ),
            }),
        }));
        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table-with-totals">
                    {isEmpty(listReports) ? <CustomEmpty type={CustomEmptyType.Report} />
                        : (
                            <>
                                {size(listReports) === 1
                                    ? <CustomEmpty />
                                    : (
                                        <>
                                            <h4 className='report-title'>PTO Report</h4>
                                            <br />
                                            <GridView
                                                showExportButton
                                                exportableColumns={exportableColumns}
                                                data={listReports}
                                                onChange={this.onTableChange}
                                                disablePagination 
                                            />
                                        </>
                                    )}
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    ptoReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    ptoReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        ptoReport: state.report.ptoReport,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
