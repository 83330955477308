import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';
import { Calendar } from '@carbon/icons-react'
import { DatePicker, Input, Button } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as eventsActions from '../../../core/events/eventsActions';
import * as assetActions from '../../../core/asset/assetActions';

class EventFilterForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Asset">
                    {getFieldDecorator('asset_info', {

                    })(
                        <Input placeholder="Enter Name/Registration" />,
                    )}
                </Form.Item>
                <Form.Item label="Date from">
                    {getFieldDecorator('date_from', {

                    })(
                        <DatePicker 
                            placeholder="Date From" 
                            allowClear={false}
                            suffixIcon={<Calendar />}
                        />,
                    )}
                </Form.Item>
                <Form.Item label="Date to">
                    {getFieldDecorator('date_to', {

                    })(
                        <DatePicker 
                            placeholder="Date To" 
                            allowClear={false}
                            suffixIcon={<Calendar />}
                        />,
                    )}
                </Form.Item>
                <Form.Item label="Driver">
                    {getFieldDecorator('driver', {

                    })(
                        <Input placeholder="Enter Driver Name" />,
                    )}
                </Form.Item>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                    >
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

const EventFilter = Form.create({ name: 'event_filter_form' })(EventFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventFilter);
