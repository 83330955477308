/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../../../components/layout/Layout';
import FilterSidebar from '../../../../components/FilterSidebar';
import Icon from '../../../../components/elements/Icon';
import ReportFilter, { DATE_MODE_RANGE } from '../../ReportFilter';
import ReportTab from './tabs/ReportTab';

export default function () {
    const {
        eventsTabLoaded, eventsTabFilter, filterSidebar, filters, imperialUnit
    } = this.state;
    const { user } = this.props;

    return (
        <Layout
            className="events"
            title={<div>Timesheet Report</div>}
            headerTitle={<div>Timesheet Report</div>}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar displayFilter={filterSidebar}>
                    <ReportFilter
                        onSubmit={this.onEventsTabFilterChange}
                        onChangeViewType={this.onChangeViewType}
                        filters={filters}
                        showDivision
                        showTimesheetReportRowType
                        dateMode={DATE_MODE_RANGE}
                    />
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <div className="section__action-bar" />
                                <ReportTab
                                    toggleFilterSidebar={() => this.setState({ filterSidebar: !filterSidebar })}
                                    eventsTabFilter={eventsTabFilter}
                                    setLoadedState={this.eventsTabLoaded}
                                    eventsTabLoaded={eventsTabLoaded}
                                    imperialUnit={imperialUnit}
                                    onTableChange={this.onTableChange}
                                    setUnit={this.setUnit}
                                    onExportCSV={this.onExportCSV}
                                    reportHasBeenFetched={this.state.reportHasBeenFetched}
                                    journeyOrDay={this.state.journeyOrDay}
                                    onPaginationChange={this.onPaginationChange}
                                />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
