import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Spin } from 'antd';
import { isEmpty } from 'underscore';
import moment from 'moment';

import GridView from '../../components/elements/GridView/index';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import FilterForm from './FilterForm';

import './drivers.scss';

const { TabPane } = Tabs;

export default function () {
    const { driver, division, user } = this.props;
    const { searchParams } = this.state;

    const formattedData = [];

    if (!isEmpty(driver.drivers)) {
        driver.drivers.map((item, key) => {
            formattedData.push({
                name: item.full_name,
                last_journey: item.last_online ? moment(item.last_online).format('DD/MM/YYYY HH:mm') : '-',
                avg_score: `${Math.round(item.average_score)}%`,
                division: !isEmpty(item.division_name) ? item.division_name : '-',
                actions: (
                    <div>
                        <Link to={`/drivers/view/${item.id}`} className="d-flex align-center justify-between">
                            <div />
                            <Icon name="chevron-right" className="ml-2" />
                        </Link>
                    </div>),
            });
        });
    }

    return (
        <Layout
            data-test="pages-drivers"
            className="drivers"
            title={<div>Drivers</div>}
            headerTitle={<div>Drivers Page</div>}
            showFooter={false}>
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar
                    displayFilter={true}
                >
                    <FilterForm searchParams={searchParams} onSubmit={(filterData) => this.filterDrivers(filterData)} departments={driver.departments} divisions={division.divisionOptions} />
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <Spin spinning={driver?.driversFetching}>
                                    <div style={{ marginTop: '-32px' }}>
                                        <GridView
                                            // filterTemplate='<div><div style="display: inline-block; width: 45%">{{label}}</div><div style="display: inline-block; width: 45%">{{element}}</div><div></div></div>'
                                            data={formattedData}
                                            onChange={this.handleTableChange}
                                            sortableColumns={['driver_id', 'name', 'last_journey', 'avg_score', 'department']}
                                            // filterColumns={filterColumns}
                                            pagination={{
                                                total: driver.pagination.totalCount,
                                                pageSize: driver.pagination.perPage,
                                                showSizeChanger: false,
                                                onChange: (page) => this.paginationChange(page),
                                            }}
                                            scroll={{
                                                x: 'max-content',
                                                y: 'calc(63vh)', 
                                            }}
                                        />
                                    </div>
                                </Spin>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
