import React from 'react';
import { 
    ArrowLeft,
    ArrowRight,
    Bullhorn,
    Calendar,
    ChartLine,
    ChevronUp,
    ChevronLeft,
    ChevronRight,
    Close,
    Download,
    EarthEuropeAfrica,
    Edit,
    Email,
    Filter,
    Fire,
    FitToScreen,
    HeatMap_02,
    Information,
    Location,
    LocationStar,
    LogoYoutube,
    Meter,
    Notification,
    OverflowMenuVertical,
    Pause,
    Pin,
    Plan,
    Play,
    Renew,
    Report,
    Road,
    Satellite,
    Search,
    Settings,
    Share,
    SkipBack,
    SkipForward,
    Time,
    TrafficEvent,
    TriangleDownOutline,
    Upload,
    User,
    VehicleConnected,
    Video,
    View,
    VolumeUpAlt,
    Warning,
} from '@carbon/icons-react';

const DEFAULT_ICON_SIZE: number = 16;
const DEFAULT_ICON_CLASSNAME = 'map-toolbar-btn';

/**
 * This function is used to patch over the old icons used in toolbars and a few other places with newer ones. 
 * If possible I recommend using the normal approach in-place e.g. <Email /> instead of using this func.
 * Returns rendered component from IBM Carbon Icons: https://carbondesignsystem.com/elements/icons/library/
 * @param name defined as strings already used in the app, e.g. "events"
 * @param size size of the icon in pixels
 * @returns React.JSX.Element
 */
export const getIbmIconComponent = (
    name: string,
    size: number = DEFAULT_ICON_SIZE,
): React.JSX.Element => {
    switch (name) {
        case "envelope":
            return (
                <Email 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "person":
        case "drivers":
        case "user":
            return (
                <User 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "clock":
            return (
                <Time 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}
                />
        );
        case "arrow-left":
            return (
                <ArrowLeft 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "arrow-right":
            return (
                <ArrowRight 
                    size={size}  
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "assets":
            return (
                <VehicleConnected 
                    size={size}  
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "car":
            return (
                <TrafficEvent 
                    size={size}  
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "calendar":
            return (
                <Calendar 
                    size={size}  
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "data":
            return (
                <ChartLine 
                    size={size}  
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "download":
            return (
                <Download 
                    size={size}  
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "earth":
            return (
                <EarthEuropeAfrica 
                    size={size}  
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "events":
        case "bell":
            return (
                <Notification 
                    size={size}  
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "fire":
            return (
                <HeatMap_02 
                    size={size}  
                    className={DEFAULT_ICON_CLASSNAME}
                />
            );
        case "hamb-with-dots":
        case "funnel":
            return (
                <Filter 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "info":
            return (
                <Information 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "location":
        case "target":
            return (
                <Location 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "magnifier":
            return (
                <Search 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "map":
            return (
                <Plan 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "pin-down-left":
        case "pin-down":
        case "pin-left":
            return (
                <Pin 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "reports":
            return (
                <Report 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "road":
            return (
                <Road 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "satellite":
            return (
                <Satellite  
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "speedometer":
            return (
                <Meter 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "triangle-down":
            return (
                <TriangleDownOutline  
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "video":
            return (
                <Video 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "warning":
            return (
                <Warning 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "youtube":
            return (
                <LogoYoutube
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "chevron-down":
            return (
                <Filter 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "chevron-left":
            return (
                <ChevronLeft 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "chevron-right":
            return (
                <ChevronRight 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "chevron-up":
            return (
                <ChevronUp 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "cross":
            return (
                <Close 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "eye":
            return (
                <View 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "menu":
            return (
                <OverflowMenuVertical 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "pencil":
            return (
                <Edit 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "refresh":
            return (
                <Renew 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "share":
        case "share2":
            return (
                <Share 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "upload":
            return (
                <Upload 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "fast-forward":
            return (
                <SkipForward
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "fast-rewind":
            return (
                <SkipBack
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "fullscreen":
            return (
                <FitToScreen 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "play-arrow":
        case "play":
            return (
                <Play 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "volume-up":
            return (
                <VolumeUpAlt 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "settings":
            return (
                <Settings 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "pause":
            return (
                <Pause 
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        case "alert":
            return (
                <Bullhorn
                    size={size} 
                    className={DEFAULT_ICON_CLASSNAME}    
                />
            );
        default:
            return (<></>);
    }
}