import React, { useEffect, useRef, useState } from 'react';
import Icon from '@ant-design/icons';
import { Popover } from 'antd';
import spriteSrc from '../assets/images/sprite.svg';
import ArrowSVG from '../assets/images/ArrowSVG';
import CenterMapMarkerImg from '../assets/images/CenterMapMarkerImg';

function ArrowIcon(props) {
    return (
        <Icon 
            component={ArrowSVG} 
            className="scorch-map-marker-arrow" 
            {...props} />
    );
}

function getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font || getComputedStyle(document.body).font;
    return context.measureText(text).width;
}

function PathPosition(props) {
    if (props.content && props.showInfoBox) {
        return (
            <Popover title={props.title || null} content={props.content}>
                <div className="scorch-map-position">{props.text}</div>
            </Popover>
        );
    }
    return <div className="scorch-map-position">{props.text}</div>;
}

function PathDot(props) {
    if (props.content && props.showInfoBox) {
        return (
            <Popover title={props.title || null} content={props.content}>
                <div className="scorch-map-dot">{props.text}</div>
            </Popover>
        );
    }
    return <div className="scorch-map-dot" />;
}

function PathInfo(props) {
    const localMarker = {};
    localMarker.record_id = props.data.record_id || null;
    localMarker.is_event = true;

    return (
        <Popover title={props.title} content={props.content} {...props.optional}>
            <div
                className="scorch-map-asset"
                onClick={() => props.pathInfoOnClick(props.data)}>
                {props.icon || props.title}
            </div>
        </Popover>
    );
}

function Asset(props) {
    const { currentHoveredAsset } = props;
    let assetPillClass = props.asset.currentMarker && props.asset.currentMarker.showArrowIcon ? 'scorch-map-asset-pill' : 'scorch-map-asset';
    const divisionColor = props && props.asset && props.asset.division_color ? props.asset.division_color : '';

    // handling hover from map, and hover from assetSearchSidebar in iss
    if (assetPillClass === 'scorch-map-asset-pill') {
        if (divisionColor === 'black' || divisionColor === '#000000') {
            if (currentHoveredAsset == props.asset.asset_id) {
                assetPillClass = 'scorch-map-asset-pill-black-hovered';
            } else {
                assetPillClass = 'scorch-map-asset-pill-black';
            }
        } else if (currentHoveredAsset == props.asset.asset_id) {
            assetPillClass = 'scorch-map-asset-pill-hovered';
        }
    }

    if (props.asset.currentMarker && props.asset.currentMarker.image) {
        const imageComponent = (
            <img
                className="scorch-map-marker-image"
                src={props.asset.currentMarker.image}
                style={{
                    WebkitTransform: `rotate(${props.asset.currentMarker.angle}deg)`,
                    transform: `rotate(${props.asset.currentMarker.angle}deg)`
                }}
                data-testid="map-asset"
            />
        );
        if (props.showInfoBox) {
            // double popover is used for hover on marker & hover on list separately
            return (
                <Popover title={props.asset.currentMarker.title || props.asset.title || ''} content={props.markerPopover} {...props.optional}>
                    <Popover title={props.asset.currentMarker.title || props.asset.title || ''} content={props.markerPopover}>
                        {imageComponent}
                    </Popover>
                </Popover>
            );
        } 
        return imageComponent;
    }
    if (props.asset.currentMarker && props.markerPopover) {
        if (props.showInfoBox) {
            // double popover is used for hover on marker & hover on list separately
            return (
                <Popover title={props.asset.currentMarker.title || props.asset.title || ''} content={props.markerPopover} {...props.optional}>
                    <Popover title={props.asset.currentMarker.title || props.asset.title || ''} content={props.markerPopover}>
                        <div
                            className={`scorch-map-asset-pill ${props.asset.className || ''}`}
                            style={{ backgroundColor: divisionColor }}
                            onClick={() => props.markerOnClick(props.asset.currentMarker)}
                            onMouseEnter={() => props.markerOnMouseEnter(props.asset.currentMarker)}
                            onMouseLeave={() => props.markerOnMouseLeave()}
                            data-testid="map-asset">
                            {props.asset.currentMarker && props.asset.currentMarker.showArrowIcon
                                ? (
                                    <div style={{ display: 'flex', padding: '2px 0px' }}>
                                        <div className="arrow">
                                            <ArrowIcon
                                                style={{
                                                    color: props.asset.is_idle ? '#d69a00' : (props.isOnline ? '#1D8038' : 'red'),
                                                    WebkitTransform: `rotate(${props.asset.currentMarker.angle}deg)`,
                                                    transform: `rotate(${props.asset.currentMarker.angle}deg)`,
                                                }} />
                                        </div>
                                        <div className="assetName" style={{ width: getTextWidth(props.asset.title) * 0.6 + 15 }}>
                                            { props.asset.title }
                                        </div>
                                    </div>
                                )
                                : ((props.asset.currentMarker && (props.asset.currentMarker.icon || props.asset.title)) || '')}
                        </div>
                    </Popover>
                </Popover>
            );
        }
        return (
            <Popover title={props.asset.currentMarker.title || props.asset.title || ''} content={props.markerPopover} {...props.optional}>
                <Popover title={props.asset.currentMarker.title || props.asset.title || ''} content={props.markerPopover}>
                    <div
                        className={`${assetPillClass} ${props.asset.className || ''}`}
                        style={{ backgroundColor: divisionColor }}
                        onClick={() => props.markerOnClick(props.asset.currentMarker)}
                        onMouseEnter={() => props.markerOnMouseEnter(props.asset.currentMarker)}
                        onMouseLeave={() => props.markerOnMouseLeave()}
                        data-testid="map-asset">
                        {props.asset.currentMarker && props.asset.currentMarker.showArrowIcon
                            ? (
                                <div style={{ display: 'flex', padding: '2px 0px' }}>
                                    <div className="arrow">
                                        <ArrowIcon
                                            style={{
                                                color: props.asset.is_idle ? '#d69a00' : (props.isOnline ? '#1D8038' : 'red'),
                                                WebkitTransform: `rotate(${props.asset.currentMarker.angle}deg)`,
                                                transform: `rotate(${props.asset.currentMarker.angle}deg)`,
                                            }} />
                                    </div>
                                </div>
                            )
                            : ((props.asset.currentMarker && (props.asset.currentMarker.icon || props.asset.title)) || '')}
                    </div>
                </Popover>
            </Popover>
        );
    }
    return (
        <div
            className={`${assetPillClass} ${props.asset.className || ''}`}
            style={{ backgroundColor: divisionColor }}
            onClick={() => props.markerOnClick(props.asset.currentMarker)}
            onMouseEnter={() => props.markerOnMouseEnter(props.asset.currentMarker)}
            onMouseLeave={() => props.markerOnMouseLeave()}
            data-testid="map-asset">
            {props.asset.currentMarker && props.asset.currentMarker.showArrowIcon
                ? (
                    <div style={{ display: 'flex', padding: '2px 0px' }}>
                        <div className="arrow">
                            <ArrowIcon
                                style={{
                                    color: props.asset.is_idle ? '#d69a00' : (props.isOnline ? '#1D8038' : 'red'),
                                    WebkitTransform: `rotate(${props.asset.currentMarker.angle}deg)`,
                                    transform: `rotate(${props.asset.currentMarker.angle}deg)`,
                                }} />
                        </div>
                    </div>
                )
                : ((props.asset.currentMarker && (props.asset.currentMarker.icon || props.asset.title)) || '')}
        </div>
    );
}

function RoadArrow(props) {
    return (
        <div className="scorch-map-custom-asset">
            <div className="arrow custom-arrow">
                <CustomIcon className="icon" name="chevron-up" style={{ WebkitTransform: `rotate(${props.asset.angle}deg)`, transform: `rotate(${props.asset.angle}deg)` }} />
            </div>
        </div>
    );
}

function Marker(props) {
    const [placement, setPlacement] = useState('top');
    const [isVisible, setVisibilty] = useState(0);
    const [showPopover, setShowPopover] = useState(0);

    const popoverRef = useRef(null);
    const arrowRef = useRef(null);
    const { currentHoveredAsset } = props;
    const divisionColor = props && props.marker && props.marker.division_color ? props.marker.division_color : '';
    let assetPillclassName = 'scorch-map-asset-pill';

    useEffect(() => {
        if (arrowRef.current && showPopover && popoverRef?.current?.clientWidth > 0) {
            const targetRect = arrowRef.current.getBoundingClientRect();
            // Check for overlap
            const ell = document.elementFromPoint(targetRect.x, targetRect.y);
            if (ell && ell.closest('.ant-popover')) {
                if (placement == 'top') {
                    setPlacement('left');
                } else {
                    setPlacement('top');
                }
            }
        }

        return () => { };
    }, [props.markerPopover, isVisible, popoverRef?.current?.clientWidth]);

    const onOpenChange = (popVisible) => {
        setVisibilty(popVisible);
        if (!popVisible) {
            setShowPopover(0);
        }
    };

    // handling hover from map, and hover from assetSearchSidebar in iss
    if (divisionColor == 'black' || divisionColor == '#000000') {
        if (currentHoveredAsset == props.marker.asset_id) {
            assetPillclassName = 'scorch-map-asset-pill-black-hovered';
        } else {
            assetPillclassName = 'scorch-map-asset-pill-black';
        }
    } else if (currentHoveredAsset == props.marker.asset_id) {
        assetPillclassName = 'scorch-map-asset-pill-hovered';
    }

    if (props.marker.image) {
        if ((props.showInfoBox || props.marker.forceInfoBox)) {
            // double popover is used for hover on marker & hover on list separately
            return (
                <Popover title={props.marker.title || ''} content={props.markerPopover} {...props.optional}>
                    <Popover title={props.marker.title || ''} content={props.markerPopover}>
                        <img
                            className="scorch-map-marker-image"
                            src={props.marker.image}
                            style={{
                                WebkitTransform: `rotate(${props.marker.angle}deg)`,
                                transform: `rotate(${props.marker.angle}deg)`
                            }}
                            data-testid="map-marker"
                        />
                    </Popover>
                </Popover>
            );
        }
        return (
            <img
                className="scorch-map-marker-image"
                src={props.marker.image}
                style={{
                    WebkitTransform: `rotate(${props.marker.angle}deg)`,
                    transform: `rotate(${props.marker.angle}deg)`
                }}
                data-testid="map-marker"
            />
        );
    }
    if (props.markerPopover) {
    // get reference for the content to know if it actually is visible on screen or not
        const content = (
            <div ref={popoverRef}>
                {props.markerPopover}
            </div>
        );
        if (props.marker && props.marker.is_event && props.marker.is_event === true) {
            return (
                <Popover title={props.marker.title || ''} content={props.markerPopover} {...props.optional}>
                    <Popover
                        title={props.marker.title || ''}
                        content={content}
                        placement={placement}
                        onVisibleChange={onOpenChange}>
                        <div
                            className={`scorch-map-asset ${props.marker.className || ''}`}
                            style={{ backgroundColor: divisionColor }}
                            onClick={() => props.markerOnClick(props.marker)}
                            onMouseEnter={() => props.markerOnMouseEnter(props.marker)}
                            onMouseLeave={() => props.markerOnMouseLeave()}
                            data-testid="map-marker">
                            {props.marker.showArrowIcon
                                ? (
                                    <div style={{ display: 'flex', padding: '2px 0px' }} ref={arrowRef}>
                                        <div className="arrow">
                                            <ArrowIcon
                                                style={{
                                                    color: props.marker.is_idle ? '#d69a00' : (props.isOnline ? '#1D8038' : 'red'),
                                                    WebkitTransform: `rotate(${props.marker.angle}deg)`,
                                                    transform: `rotate(${props.marker.angle}deg)`,
                                                }} />
                                        </div>
                                    </div>
                                )
                                : (props.marker.icon || props.marker.title)}
                        </div>
                    </Popover>
                </Popover>
            );
        } if (props.showInfoBox || props.marker.forceInfoBox) {
            const markerContent = (
                <div
                    className={`${assetPillclassName} ${props.marker.className || ''}`}
                    style={{ backgroundColor: divisionColor }}
                    onClick={() => props.markerOnClick(props.marker)}
                    onMouseEnter={() => {
                        // if the pop over is open, it means we have already requested the data
                        // if this isn't there then it will fetch the location again
                        if (showPopover) {
                            return;
                        }
                        props.markerOnMouseEnter(props.marker);
                        setShowPopover(1);
                    }}
                    onMouseLeave={() => { props.markerOnMouseLeave(); }}
                    data-testid="map-marker">
                    {props.marker.showArrowIcon
                        ? (
                            <div style={{ display: 'flex', padding: '2px 0px' }} ref={arrowRef}>
                                <div className="arrow">
                                    <ArrowIcon
                                        style={{
                                            color: props.marker.is_idle ? '#d69a00' : (props.isOnline ? '#1D8038' : 'red'),
                                            WebkitTransform: `rotate(${props.marker.angle}deg)`,
                                            transform: `rotate(${props.marker.angle}deg)`,
                                        }} />
                                </div>
                                <div className="assetName" style={{ width: getTextWidth(props.marker.name) * 0.6 + 15 }}>
                                    {props.marker.name}
                                </div>
                            </div>
                        )
                        : (props.marker.icon || props.marker.title)}
                </div>
            );

            if (!showPopover) {
                return markerContent;
            } else {
                return (
                    <Popover title={props.marker.title || ''} content={props.markerPopover} {...props.optional}>
                        <Popover
                            title={props.marker.title || ''}
                            content={content}
                            placement={placement}
                            onVisibleChange={onOpenChange}>
                            {markerContent}
                        </Popover>
                    </Popover>
                );
            }
            // double popover is used for hover on marker & hover on list separately
        } if (props.marker.showArrowIcon && showPopover) {
            return (
                <Popover title={props.marker.title || ''} content={props.markerPopover} {...props.optional}>
                    <Popover
                        title={props.marker.title || ''}
                        content={content}
                        placement={placement}
                        onVisibleChange={onOpenChange}
                        data-testid="map-marker">
                        <div
                            className={`${assetPillclassName} ${props.marker.className || ''}`}
                            style={{ backgroundColor: divisionColor }}
                            onClick={() => props.markerOnClick(props.marker)}
                            onMouseLeave={() => { props.markerOnMouseLeave(); }}>
                            {props.marker.showArrowIcon
                                ? (
                                    <div style={{ display: 'flex', padding: '2px 0px' }} ref={arrowRef}>
                                        <div className="arrow">
                                            <ArrowIcon
                                                style={{
                                                    color: props.marker.is_idle ? '#d69a00' : (props.isOnline ? '#1D8038' : 'red'),
                                                    WebkitTransform: `rotate(${props.marker.angle}deg)`,
                                                    transform: `rotate(${props.marker.angle}deg)`,
                                                }} />
                                        </div>
                                    </div>
                                )
                                : (props.marker.icon || props.marker.title)}
                        </div>
                    </Popover>
                </Popover>
            );
        }
        return (
            <div
                className={`${assetPillclassName} ${props.marker.className || ''}`}
                style={{ backgroundColor: divisionColor }}
                onClick={() => props.markerOnClick(props.marker)}
                onMouseEnter={() => {
                    props.markerOnMouseEnter(props.marker);
                    setShowPopover(1);
                }}
                onMouseLeave={() => {
                    props.markerOnMouseLeave();
                    setShowPopover(0);
                }}
                data-testid="map-marker">
                {props.marker.showArrowIcon
                    ? (
                        <div style={{ display: 'flex', padding: '2px 0px' }}>
                            <div className="arrow">
                                <ArrowIcon
                                    style={{
                                        color: props.marker.is_idle ? '#d69a00' : (props.isOnline ? '#1D8038' : 'red'),
                                        WebkitTransform: `rotate(${props.marker.angle}deg)`,
                                        transform: `rotate(${props.marker.angle}deg)`,
                                    }} />
                            </div>
                        </div>
                    )
                    : (props.marker.icon || props.marker.title)}
            </div>
        );
    }

    return (
        <div
            className={`scorch-map-asset-pill ${props.marker.className || ''}`}
            style={{ backgroundColor: divisionColor }}
            onClick={() => props.markerOnClick(props.marker)}
            onMouseEnter={() => props.markerOnMouseEnter(props.marker)}
            onMouseLeave={() => props.markerOnMouseLeave()}
            data-testid="map-marker">
            {props.marker.showArrowIcon
                ? (
                    <div style={{ display: 'flex', padding: '2px 0px' }}>
                        <div className="arrow">
                            <ArrowIcon
                                style={{
                                    color: props.marker.is_idle ? '#d69a00' : (props.isOnline ? '#1D8038' : 'red'),
                                    WebkitTransform: `rotate(${props.marker.angle}deg)`,
                                    transform: `rotate(${props.marker.angle}deg)`,
                                }} />
                        </div>
                    </div>
                )
                : (props.marker.icon || props.marker.title)}
        </div>
    );
}

function CenterMapMarker(props) {
    return (
        <div className="center-map-marker-container">
            <CenterMapMarkerImg className="center-map-marker" />
        </div>
    );
}

function CustomIcon(props) {
    return (
        <svg className={`icon icon-${props.name.toLowerCase()}  ${props.className || ''} ${!props.visible ? 'hidden' : ''}`} style={props.style ? props.style : {}}>
            <use xlinkHref={`${spriteSrc}#icon-${props.name.toLowerCase()}`} />
        </svg>
    );
}

const currentButton = (map, maps, callback) => {
    const controlDiv = document.createElement('div');
    const firstChild = document.createElement('button');
    firstChild.style.backgroundColor = '#fff';
    firstChild.style.border = 'none';
    firstChild.style.outline = 'none';
    firstChild.style.width = '40px';
    firstChild.style.height = '40px';
    firstChild.style.borderRadius = '2px';
    firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
    firstChild.style.cursor = 'pointer';
    firstChild.style.marginRight = '10px';
    firstChild.style.padding = '0px';
    firstChild.title = 'Your Location';
    controlDiv.appendChild(firstChild);
    const secondChild = document.createElement('div');
    secondChild.style.margin = '5px';
    secondChild.style.width = '32px';
    secondChild.style.height = '32px';
    secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
    secondChild.style.backgroundSize = '297px';
    secondChild.style.backgroundPosition = '0px 0px';
    secondChild.style.backgroundRepeat = 'no-repeat';
    secondChild.id = 'current_location_img';
    firstChild.appendChild(secondChild);
    firstChild.addEventListener('click', callback);

    // firstChild.addEventListener('click', () => {
    //
    // })
    controlDiv.index = 1;
    map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
};

Asset.defaultProps = {
    markerOnMouseEnter: () => {},
    markerOnMouseLeave: () => {},
};

export {
    PathPosition,
    PathDot,
    PathInfo,
    Asset,
    CustomIcon,
    currentButton,
    Marker,
    CenterMapMarker,
    RoadArrow,
};
