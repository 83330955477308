import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';

import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';

import * as driverActions from '../../../core/driver/driverActions';
import * as globalActions from '../../../core/global/globalActions';
import { secondsToHms } from '../../../core/utils/functions';
import LocationFetcher from '../../../components/elements/LocationFetcher';

class DriverJourneyHistoryTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        const {
            actions, setLoadedState, journeyHistoryTabLoaded, driverId, driverJourneyFilters,
        } = this.props;

        const params = { driverId, page: 1 };
        if (!isEmpty(driverJourneyFilters)) {
            const { is_authorised, dateRange } = driverJourneyFilters;
            params.is_authorised = is_authorised;
            if (!isEmpty(dateRange)) {
                const [dateFrom, dateTo] = dateRange;
                params.date_from = dateFrom;
                params.date_to = dateTo;
            }
        }

        if (!journeyHistoryTabLoaded) {
            actions.getDriverJourneyRequest(params);
            setLoadedState();
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange, driverJourneyPagination } = this.props;

        if (pagination && pagination.current == driverJourneyPagination.currentPage) {
            onTableChange(pagination, filters, sorter, extra);
        }
    };

    updateLocation(id) {
        const { actions } = this.props;
        actions.updateJourneyLocationRequest({ id });
    }

    render() {
        const {
            driverJourney, driverJourneyPagination, isFetching, actions, driverId, journeyHistoryFirstLoad,
        } = this.props;

        const listDriverJourneys = driverJourney ? driverJourney.map((journey) => {
            const circleColour = journey.events > 0 ? 'red' : 'green';
            const tempJourney = {};
            const timeDriven = secondsToHms(journey.time_driven);

            tempJourney.start_time = moment(journey.start, 'YYYY-MM-DD HH:mm:ss').format('ddd DD/MM/YYYY HH:mm:ss');
            tempJourney.end_time = journey.end != null ? moment(journey.end, 'YYYY-MM-DD HH:mm:ss').format('ddd DD/MM/YYYY HH:mm:ss') : '';
            tempJourney.time_driven = timeDriven || '0 secs';
            const startLat = journey.start_area.split(',')[0];
            const startLng = journey.start_area.split(',')[1];
            const startAreaText = (
                <LocationFetcher
                    key={`start-${journey.id}`}
                    lat={startLat}
                    lon={startLng}
                />
            );
            tempJourney.start_location = startAreaText;
            const endLat = journey.end_area.split(',')[0];
            const endLng = journey.end_area.split(',')[1];
            const endAreaText = (
                <LocationFetcher
                    key={`end-${journey.id}`}
                    lat={endLat}
                    lon={endLng} 
                />
            );
            tempJourney.end_location = endAreaText;
            tempJourney.score = `${journey.score}%`;
            tempJourney.events = <span className={`circle ${circleColour}`}>{journey.events}</span>;
            tempJourney.actions = (
                <Link
                    to={`/journey/${journey.id}`}
                    style={{ float: 'right' }}>
                    <Icon name="chevron-right" className="ml-2" />
                </Link>
            );
            return tempJourney;
        }) : [];

        let spinning = false;
        for (let i = 0; i < isFetching.length; i++) {
            const item = isFetching[i];
            if (item.GET_DRIVER_JOURNEY) {
                spinning = true;
                break;
            }
        }

        return (
            <Spin spinning={spinning}>
                {journeyHistoryFirstLoad ? (
                    <div className="h-centered-text-container">
                        <p>(Filtering by Today)</p>
                    </div>
                ) : null}
                <GridView
                    // filterTemplate='<div><div style="display: inline-block; width: 45%">{{label}}</div><div style="display: inline-block; width: 45%">{{element}}</div><div></div></div>'
                    data={listDriverJourneys}
                    onChange={this.onTableChange}
                    sortableColumns={['start_time', 'end_time', 'time_driven', 'driver', 'area_driven', 'score', 'events']}
                    pagination={{
                        total: parseInt(driverJourneyPagination.totalRecords, 10),
                        showSizeChanger: false,
                        pageSize: parseInt(driverJourneyPagination.perPageCount, 10),
                        onChange: this.onPageChange,
                    }} />
            </Spin>
        );
    }
}

DriverJourneyHistoryTab.propTypes = {
    actions: PropTypes.object.isRequired,
    driverJourneyFilters: PropTypes.object.isRequired,
    driverJourney: PropTypes.any,
};

DriverJourneyHistoryTab.defaultProps = {
    driverJourney: false,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        driverJourney: state.driver.driverJourney,
        driverJourneyPagination: state.driver.driverJourneyPagination,
        isFetching: state.driver.isFetching,
        driverJourneyFilters: state.driver.driverJourneyFilters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...driverActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DriverJourneyHistoryTab);
