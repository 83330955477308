import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as videoActions from '../../core/video/videoActions';
import * as eventsActions from '../../core/events/eventsActions';
import * as assetActions from '../../core/asset/assetActions';
import * as driverActions from '../../core/driver/driverActions';
import * as globalActions from '../../core/global/globalActions';

import history from '../../core/utils/history';

import DOM from './video';
import { fetchApiAuth } from '../../core/utils/api';

class Video extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.googleMapAccess = React.createRef();
        this.loadingDvrsRequest = null;

        this.state = {
            // eslint-disable-next-line react/no-unused-state
            selectedTabKey: 'video-search',
            locationSearch: false,
            mapOpen: false,
            showSideBar: true,
            videoSearchFiltered: false,
            latitude: null,
            longitude: null,
            geometry: null,
            openSidebar: true,
            showFilterSidebar: true,
        };
        this.onFilterCallback = () => null;
        this.onRequestFilterCallback = () => null;
        this.onLocationSearchCallback = () => null;
        this.onSearchCallback = () => null;
    }

    checkDvrStatus() {
        if (!this.loadingDvrsRequest) {
            const opts = {
                method: 'POST',
                url: '/video/get-dvr-online-status',
            };
            this.loadingDvrsRequest = fetchApiAuth(opts);
        }
        return this.loadingDvrsRequest;
    }

    componentWillUnmount() {
        this.loadingDvrsRequest = null;
    }

    async componentDidMount() {
        const {
            actions, match, retainFilter,
        } = this.props;
        const { params } = match;
        const selectedTabKey = params?.selectedTab;
        if (selectedTabKey) {
            this.setState({ selectedTabKey });

        }
        await this.checkDvrStatus();
        actions.getAssetOptionsRequest();
        actions.getDriverListRequest();
        actions.getDivisionListRequest();
        actions.getVehicleTypesRequest();

        const backFromVideoView = (document.referrer).includes('/video/view/');
        const { pathname } = window.location;
        if (retainFilter === true && backFromVideoView === true) {
            this.setState({ videoSearchFiltered: true });
        } else if (pathname.includes('/location')) {
            this.setState({ videoSearchFiltered: true });
        } else {
            this.setState({ videoSearchFiltered: false });
        }
        actions.setRetainFilter(false);
    }

    componentDidUpdate() {
        window.onpopstate = (e) => {
            const path = window.location.pathname;

            if (path.includes('video-search')) {
                this.setState({ selectedTabKey: 'video-search' });
            } else if (path.includes('cached-videos')) {
                this.setState({ selectedTabKey: 'cached-videos' });
            } else if (path.includes('live-videos')) {
                this.setState({ selectedTabKey: 'live-videos' });
            }

            const { actions } = this.props;

            actions.updateVideoCameraSelection([]);
            actions.toggleVideoSelectionScreen(true);
        };
    }

    changeTab(selectedTabKey, showSideBar = null) {
        this.setState({ selectedTabKey });

        if (showSideBar !== null) {
            this.setState({ showSideBar });
        }
    }

    setLocationSearch = (locationSearch) => {
        this.setState({ locationSearch, mapOpen: locationSearch });
    };

    setMapOpen = (mapOpen) => {
        this.setState({ mapOpen });
    };

    registerOnFilterCallback = (fn) => {
        this.onFilterCallback = fn;
    };

    registerOnSearchCallback = (fn) => {
        this.onSearchCallback = fn;
    };

    registerOnRequestFilterCallback = (fn) => {
        this.onRequestFilterCallback = fn;
    };

    registerOnLocationSearchCallback = (fn) => {
        this.onLocationSearchCallback = fn;
    };

    setShouldShowSidebar = (shouldShow) => {
        this.setState({ showSideBar: shouldShow, openSidebar: shouldShow });
    };

    liveVideoFilterSubmit = (values) => {
        const { actions } = this.props;
        actions.getAssetsVideoListRequest(values);
    };

    handleHistoricFilter = (a) => this.onFilterCallback(a);

    handleHistoricSearchFilter = (a) => {
        const { mapOpen } = this.state;

        this.setState({
            videoSearchFiltered: true,
            latitude: a.latitude,
            longitude: a.longitude,
            geometry: a.geometry,
            locationSearch: a.location_search,
            mapOpen: a.location_search || mapOpen,
            // map open if search with location
        });
        this.onSearchCallback(a);
    };

    handleRequestedFilter = (a) => this.onRequestFilterCallback(a);

    handleLocationSearchFilter = (a) => this.onLocationSearchCallback(a);

    handleTabClick = (key) => {
        const { selectedTabKey } = this.state;
        if (selectedTabKey !== key) {
            this.setState({ selectedTabKey: key, videoSearchFiltered: false });
            history.push(`/video/${key}`);
            const { actions } = this.props;
            actions.updateVideoCameraSelection([]);
            actions.toggleVideoSelectionScreen(true);
            actions.updateVideoSearch({ assetReg: '' });
            this.setShouldShowSidebar(true);
        }
    };

    render() {
        return this.view();
    }
}

Video.defaultProps = {
    assetId: 0,
};

Video.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    assetId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

function mapStateToProps(state, ownProps) {
    const assetId = ownProps.match.params && ownProps.match.params.assetId ? ownProps.match.params.assetId : 0;
    return {
        ...ownProps,
        showSelectionScreen: state.video.showSelectionScreen,
        user: state.user,
        assetReg: state.global.videoSearchFilters.assetReg,
        retainFilter: state.global.retainFilter,
        assetId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...videoActions,
                ...eventsActions,
                ...assetActions,
                ...driverActions,
                ...globalActions,
            },
            dispatch,
        ),
    };
}
export const VideoTest = Video;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Video);
