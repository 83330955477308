import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Col, Pagination, Row } from 'antd';
import { reject, isEmpty } from 'underscore';
import * as videoActions from '../../../core/video/videoActions';
import VideoFormBox from '../../../components/elements/VideoFormBox';
import CustomEmpty, { CustomEmptyType } from '../../../components/CustomEmpty';

class LiveVideosSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
        }
    }

    componentDidMount() {
        const {
            actions, params, toggleVideoScreen, clearLiveVideos,
        } = this.props;
        const assetId = params && params.assetId ? params.assetId : null;
        const filter = assetId ? { asset_id: assetId } : {};
        actions.getAssetsVideoListRequest(filter);
        actions.checkDvrOnlineStatusRequest();

        const urlParams = new URLSearchParams(window.location.search);
        const imei = urlParams.get('imei');
        if (!isEmpty(imei) && !isEmpty(assetId)) {
            clearLiveVideos();
            actions.getVideoLiveTabRequest({
                assetId,
                imei,
            });
            setTimeout(() => toggleVideoScreen(), 1000);
        }
    }

    toggleVideoSelect = (id, isChecked) => {
        const { selectedCameras, actions } = this.props;
        let currentVideoList = [];
        if (isChecked === false) {
            currentVideoList = reject(selectedCameras, (obj) => obj.id == id);
        } else {
            currentVideoList = selectedCameras;
            if (selectedCameras.length < 8) {
                const tmp = {};
                tmp.id = id;
                tmp.checked = isChecked;
                currentVideoList.push(tmp);
            }
        }
        currentVideoList = currentVideoList.map((camera, cameraIndex) => {
            camera.data = {
                key: `camera-sub-${camera.id}`,
            };
            return camera;
        });
        actions.updateVideoCameraSelection(currentVideoList);
    };

    render() {
        const {
            toggleVideoScreen, liveCameras, liveCamerasPagination, selectedCameras, videoOnlineStatusChecking, user,
        } = this.props;
        const { currentPage } = this.state;

        const allCameras = [];
        return (
            <>
                <div style={{ textAlign: 'center', padding: '0px 16px' }}>
                    <p style={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: 'var(--cool-gray-60)',
                        fontFamily: 'Inter',
                        marginBottom: '16px',
                    }}>
                        Select Cameras
                    </p>
                    <div className="videos">
                        {liveCameras.length > 0
                            ? (
                                <Row gutter={24} justify="center" type="flex">
                                    {liveCameras.map((camera, cameraIndex) => {
                                        const assetImage = camera.image_source || user?.userCompany?.company_image;
                                        return (
                                            <Col
                                                key={`camera-channel-${cameraIndex}`}
                                                className="mt-3 video-box-fixed-width"
                                                xs={24}
                                                sm={12}
                                                md={8}
                                                lg={6}
                                                xl={4}
                                                xxl={3}>
                                                <VideoFormBox
                                                    isFetchingStatus={videoOnlineStatusChecking}
                                                    image={assetImage}
                                                    name={camera.name || 'Unknown'}
                                                    driver={camera.full_name || 'Unknown'}
                                                    registration={camera.registration || 'Unknown'}
                                                    onSelect={this.toggleVideoSelect}
                                                    camera={camera}
                                                    selectedCameras={selectedCameras}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )
                            : (
                                <Row style={{ marginTop: '20px' }}>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ margin: 'auto' }}>
                                            <CustomEmpty type={CustomEmptyType.VideoDevices} />
                                        </div>
                                    </div>
                                </Row>
                            )}
                    </div>
                </div>
                {liveCameras.length > 0 && (
                    <>
                        <div
                            style={{ marginBottom: 10 }}
                            className='custom-pagination'
                        >
                            <Pagination
                                current={currentPage}
                                total={(parseInt(liveCamerasPagination.totalRecords, 10))}
                                pageSize={parseInt(liveCamerasPagination.perPageCount, 10)}
                                onChange={(page, pageSize) => {
                                    this.setState({ currentPage: page });
                                    const { actions } = this.props;
                                    actions.getAssetsVideoListRequest({
                                        page,
                                    });
                                }}
                                showSizeChanger={false}
                            />
                        </div>
                        {liveCameras.length > 4 && selectedCameras.length > 1 ? (
                            <div className='bottom-bar'>
                                <Button
                                    type="primary"
                                    disabled={isEmpty(selectedCameras)}
                                    onClick={() => toggleVideoScreen(false)}
                                >
                                    View
                                </Button>
                            </div>
                        ) : (
                            <div className="mt-4 text-center">
                                <Button
                                    type="primary"
                                    disabled={isEmpty(selectedCameras)}
                                    onClick={() => toggleVideoScreen(false)}
                                >
                                    View
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        liveCameras: state.video.liveCameras,
        liveCamerasPagination: state.video.liveCamerasPagination,
        selectedCameras: state.video.selectedCameras,
        videoOnlineStatusChecking: state.video.videoOnlineStatusChecking,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...videoActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LiveVideosSelection);
