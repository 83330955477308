import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { isEmpty } from 'underscore';
import PropTypes from 'prop-types';

import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';
import { secondsToHms } from '../../../core/utils/functions';
import * as assetActions from '../../../core/asset/assetActions';
import LocationFetcher from '../../../components/elements/LocationFetcher';

class AssetJourneyHistoryTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            searchParams: {},
            orderParams: {},
        };
    }

    componentDidMount() {
        const {
            actions, setLoadedState, journeyHistoryTabLoaded, assetId, assetJourneyFilters, singleAsset
        } = this.props;

        const params = {
            assetId,
            orderBy: '-start_time',
            page: 1,
        };

        if (!isEmpty(assetJourneyFilters)) {
            const { is_authorised, dateRange } = assetJourneyFilters;
            params.is_authorised = is_authorised;
            if (!isEmpty(dateRange)) {
                const [dateFrom, dateTo] = dateRange;
                params.date_from = dateFrom;
                params.date_to = dateTo;
            }
        }

        if (!journeyHistoryTabLoaded) {
            actions.getAssetJourneyRequest(params);
            setLoadedState();
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    gridChange = (pagination, filters, sorter, extra) => {
        const { assetJourneyPagination, onTableChange } = this.props;

        if (pagination && pagination.current == assetJourneyPagination.currentPage) {
            onTableChange(pagination, filters, sorter, extra);
        }
    };

    updateLocation(id) {
        const { actions } = this.props;
        actions.updateAssetJourneyLocationRequest({ id });
    }

    render() {
        const {
            assetJourney, assetJourneyPagination, isFetching, journeyHistoryFirstLoad,singleAsset
        } = this.props;

        if (singleAsset.act_as_tracker) {
            return (
                <div className="h-centered-text-container">
                    <p>
                        Unavailable for this Asset - <a href="https://www.fleetclear.com/contact" target="_blank" rel="noreferrer">Click Here</a> to find out more
                    </p>
                </div>
            );
        }

        const listAssetJourneys = assetJourney.map((journey, journeyIndex) => {
            const circleColour = journey.events > 0 ? 'red' : 'green';
            const tempJourney = {};
            const timeDriven = secondsToHms(journey.time_driven); // Math.round(journey.time_driven * 100) / 100;
            let startAreaText = '(No GPS data)';
            let endAreaText = '(No GPS data)';
            if (journey.start_area_address) {
                 startAreaText = journey.start_area_address;
            } else if(journey.start_area) {
                let [startLat, startLng] = journey.start_area.split(',');
                if (startLat && startLng) {
                    startAreaText = (
                        <LocationFetcher
                            key={`start-${journey.id}`}
                            lat={startLat}
                            lon={startLng}
                        />
                    );
                }
            }

            if (journey.end_area_address) {
                endAreaText = journey.end_area_address;
            } else if (journey.end_area) {
                let [endLat, endLng] = journey.end_area.split(',');
                if (endLat && endLng) {
                    startAreaText = (
                        <LocationFetcher
                            key={`end-${journey.id}`}
                            lat={endLat}
                            lon={endLng}
                        />
                    );
                }
            }
            tempJourney.start_time = moment(journey.start, 'YYYY-MM-DD HH:mm:ss').format('ddd DD/MM/YYYY HH:mm:ss');
            tempJourney.end_time = moment(journey.end, 'YYYY-MM-DD HH:mm:ss').format('ddd DD/MM/YYYY HH:mm:ss');
            tempJourney.time_driven = timeDriven || '0 secs';
            tempJourney.driver = journey.driver;
            tempJourney.start_location = startAreaText;
            tempJourney.end_location = endAreaText;
            tempJourney.score = `${journey.score}%`;
            tempJourney.events = <span className={`circle ${circleColour}`}>{journey.events}</span>;
            tempJourney.actions = (
                <Link to={`/journey/${journey.id}`} style={{ float: 'right' }}>
                    <Icon name="chevron-right" className="ml-2" />
                </Link>
            );

            return tempJourney;
        });

        let spinning = false;
        for (let i = 0; i < isFetching.length; i++) {
            const item = isFetching[i];
            if (item.GET_ASSET_JOURNEY) {
                spinning = true;
            }
        }

        return (
            <Spin spinning={spinning}>
                {journeyHistoryFirstLoad ? (
                    <div className="h-centered-text-container">
                        <p>(Filtering by Today)</p>
                    </div>
                ) : null}
                <GridView
                    data={listAssetJourneys}
                    onChange={this.gridChange}
                    sortableColumns={['start_time', 'end_time', 'time_driven', 'driver', 'area_driven', 'score', 'events']}
                    pagination={{
                        total: parseInt(assetJourneyPagination.totalRecords, 10),
                        showSizeChanger: false,
                        pageSize: parseInt(assetJourneyPagination.perPageCount, 10),
                        onChange: this.onPageChange,
                    }} />
            </Spin>
        );
    }
}

AssetJourneyHistoryTab.defaultProps = {
    onPageChange: () => null,
};

AssetJourneyHistoryTab.propTypes = {
    actions: PropTypes.object.isRequired,
    assetJourneyFilters: PropTypes.object.isRequired,
    onPageChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        assetJourney: state.asset.assetJourney,
        assetJourneyPagination: state.asset.assetJourneyPagination,
        isFetching: state.asset.isFetching,
        assetJourneyFilters: state.asset.assetJourneyFilters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetJourneyHistoryTab);
