/* global window */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker, Select, Button } from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Erase, Calendar } from '@carbon/icons-react';
import * as eventsActions from '../../../core/events/eventsActions';
import * as assetActions from '../../../core/asset/assetActions';
import * as driverActions from '../../../core/driver/driverActions';
import * as globalActions from '../../../core/global/globalActions';
import * as smartWidgetActions from '../../../core/smartWidget/smartWidgetActions';

dayjs.extend(customParseFormat);

class DataEventFilterForm extends Component {
    componentDidMount() {
        const {
            actions,
            driverList,
        } = this.props;
        // actions.getEventTypesRequest({ is_event: true });
        actions.getSmartWidgetInputDataRequest();
        actions.getAssetOptionsRequest();
        if (driverList.length <= 0) {
            actions.getDriverListRequest();
        }
        actions.getDivisionListRequest();
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit, form } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const {
            form,
            inputData,
            assetOptions,
            driverList,
            divisionList,
            role,
            dataEventsTabFilter,
            assetId,
            dateFrom,
            mapDate,
        } = this.props;
        const { getFieldDecorator } = form;

        const params = dataEventsTabFilter;
        let startdate = dayjs();
        startdate = startdate.subtract(30, 'days');

        let assetInfoInitialVal = params && params.asset_info ? { initialValue: parseInt(params.asset_info, 10) } : {};
        let dateFromInitialVal = params && params.date_from ? { initialValue: dayjs(params.date_from, 'YYYY-MM-DD') } : { initialValue: startdate };
        let dateToInitialVal = params && params.date_to ? { initialValue: dayjs(params.date_to, 'YYYY-MM-DD') } : { initialValue: dayjs() };
        const inputDataInitialVal = params && params.input_data ? { initialValue: params.input_data } : {};
        const driverInitialVal = params && params.driver ? { initialValue: params.driver } : {};
        const divisionInitialVal = params && params.division ? { initialValue: params.division } : {};

        if (window.location.pathname.includes('search')) {
            if (assetId) {
                assetInfoInitialVal = { initialValue: parseInt(assetId, 10) };
            }

            if (mapDate) {
                // If user comes from main map screen then use the map date to show events
                dateFromInitialVal = { initialValue: dayjs(mapDate, 'YYYY-MM-DD') };
                dateToInitialVal = { initialValue: dayjs(mapDate, 'YYYY-MM-DD') };
            }
        } else {
            if (params && params.date_from && params.date_from !== '') {
                dateFromInitialVal = { initialValue: dayjs(params.date_from, 'YYYY-MM-DD') };
            }

            if (params && params.date_to && params.date_to !== '') {
                dateToInitialVal = { initialValue: dayjs(params.date_to, 'YYYY-MM-DD') };
            }
        }

        const dateFormat = 'DD/MM/YYYY';

        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Input Data">
                    {getFieldDecorator('input_data', {
                        ...inputDataInitialVal,
                        rules: [
                            {
                                required: true,
                                message: 'Please select input data',
                            },
                        ],
                    })(
                        <Select
                            mode="multiple"
                            placeholder="Select Input Data"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {inputData.map((input) => <Select.Option key={input.id} value={input.id}>{input.title}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Date from">
                    {getFieldDecorator('date_from', dateFromInitialVal)(
                        <DatePicker 
                            placeholder="Date From" 
                            format={dateFormat} 
                            allowClear={false}
                            suffixIcon={<Calendar />}
                        />,
                    )}
                </Form.Item>
                <Form.Item label="Date to">
                    {getFieldDecorator('date_to', dateToInitialVal)(
                        <DatePicker 
                            placeholder="Date To" 
                            format={dateFormat} 
                            allowClear={false}
                            suffixIcon={<Calendar />}
                        />,
                    )}
                </Form.Item>
                { /* option.props.children.toLowerCase() */}
                <Form.Item label="Asset">
                    {getFieldDecorator('asset_info', assetInfoInitialVal)(
                        <Select
                            showSearch
                            allowClear={<Erase size={12} />}
                            filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                            placeholder="Select Asset"
                        >
                            {assetOptions.map((a) => (
                                <Select.Option key={a.id} value={a.id}>
                                    {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                </Select.Option>
                            ))}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Driver">
                    {getFieldDecorator('driver', driverInitialVal)(
                        <Select
                            mode="multiple"
                            placeholder="Select Driver"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            <Select.Option value="all">Any</Select.Option>
                            {driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Division">
                    {getFieldDecorator('division', divisionInitialVal)(
                        <Select
                            mode="multiple"
                            placeholder="Select Division"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            <Select.Option value="all">Any</Select.Option>
                            {divisionList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                    >
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

DataEventFilterForm.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    assetOptions: PropTypes.array.isRequired,
    driverList: PropTypes.array.isRequired,
    divisionList: PropTypes.array.isRequired,
    dataEventsTabFilter: PropTypes.object.isRequired,
    assetId: PropTypes.string,
    dateFrom: PropTypes.string,
    mapDate: PropTypes.string,
    inputData: PropTypes.array.isRequired,
};

const DataEventFilter = Form.create({ name: 'event_filter_form' })(DataEventFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        assetOptions: state.asset.options,
        driverList: state.driver.driverList,
        divisionList: state.asset.divisionList,
        role: state.user.role,
        assetId: state.global.eventSearchFilters.assetId,
        mapDate: state.global.mapDate,
        dateFrom: state.global.eventSearchFilters.dateFrom,
        inputData: state.smartWidget.inputData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
            ...globalActions,
            ...smartWidgetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DataEventFilter);
