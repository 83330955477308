import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    DatePicker, Select, Button,
    Input, Row, Col,
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Erase, Calendar } from '@carbon/icons-react';
import * as assetActions from '../../../core/asset/assetActions';
import { secondsToHms } from '../../../core/utils/functions';

dayjs.extend(customParseFormat);

const {
    MAP_SLIDER_INTERVAL,
} = require('../../../core/constants').default;

class VideoSearchFilterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locationSearch: false,
            val: '',
            latitude: null,
            longitude: null,
            geometry: null,
            loadScript: true,
        };
    }

    componentDidMount() {
        const { actions, onSubmit } = this.props;
        const { latitude, longitude, geometry } = this.state;

        actions.getDivisionListRequest();
        actions.getAssetOptionsRequest();
        onSubmit({
            latitude,
            longitude,
            geometry,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if somehow the map or the location search box is triggered, don't allow the location search box to load script again
        if (
            (!prevState.locationSearch && this.state.locationSearch)
            || (!prevProps.locationSearch && this.props.locationSearch)
        ) {
            this.setState({ loadScript: false });
        }
    }

    onUpdate = (place) => {
        const {
            formatted_address,
            geometry,
        } = place;
        const latitude = geometry.location.lat();
        const longitude = geometry.location.lng();
        this.setState({
            latitude,
            longitude,
            geometry,
        });
        const { form: { setFieldsValue } } = this.props;
        setFieldsValue({
            location: formatted_address,
        });
    };

    submitForm = (e) => {
        if (e) {
            e.preventDefault();
        }
        const {
            latitude,
            longitude,
            geometry,
        } = this.state;
        const {
            onSubmit,
            form: { validateFields },
        } = this.props;
        validateFields((err, values) => {
            if (!err) {
                const newValues = values;
                newValues.latitude = latitude;
                newValues.longitude = longitude;
                newValues.geometry = geometry;
                onSubmit(newValues);
            }
        });
    };

    setLocationSearch = (e) => {
        const locationSearch = e.target.checked;

        this.setState({ locationSearch });

        if (!locationSearch) {
            this.setState({
                latitude: null,
                longitude: null,
                geometry: null,
            });
        }
    };

    render() {
        const {
            form,
            divisions,
            driverList,
            assetReg,
            assetOptions,
            mapSearchVideoSelectedDate,
            mapSearchVideoSliderValue,
            params,
            videoRetentionDays,
        } = this.props;
        const { getFieldDecorator } = form;

        let dateInitialVal = dayjs();
        let timeFromInitialVal = dayjs().subtract(1, 'hour').format('HH:mm:ss');
        let timeToInitialVal = dayjs().format('HH:mm:ss');
        if (params?.assetId === 'location') {
            timeFromInitialVal = `${secondsToHms(((mapSearchVideoSliderValue * 60) * MAP_SLIDER_INTERVAL), 'time')}:00`;
            timeToInitialVal = `${secondsToHms(((mapSearchVideoSliderValue * 60) * MAP_SLIDER_INTERVAL), 'time')}:00`;
            dateInitialVal = dayjs(mapSearchVideoSelectedDate);
        }
        let initialAssetReg;
        if (assetReg) {
            initialAssetReg = assetOptions.find((asset) => asset.reg === assetReg);
        }

        return (
            (
                <Form onSubmit={this.submitForm} layout="vertical">
                    <Form.Item label="Asset">
                        {getFieldDecorator('asset_id', {
                            initialValue: initialAssetReg ? [initialAssetReg.id] : [],
                        })(
                            <Select
                                mode="multiple"
                                showSearch
                                allowClear={<Erase size={12} />}
                                filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                                placeholder="Select Asset"
                            >
                                {assetOptions && assetOptions.map((a) => (
                                    <Select.Option key={a.id} value={a.id}>
                                        {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                    </Select.Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label="Driver">
                        {getFieldDecorator('driver', {})(
                            <Select
                                mode="multiple"
                                filterOption={(input, option) => option.props.children.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select Driver"
                                allowClear={<Erase size={12} />}
                                showSearch
                            >
                                {driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label="Division">
                        {getFieldDecorator('division_id', {
                            rules: [],
                        })(
                            <Select
                                placeholder="Select Division"
                                filterOption={(input, option) => option.props.children.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0}
                                mode="multiple"
                                style={{ width: '100%' }}
                                showSearch
                                allowClear={<Erase size={12} />}
                            >
                                {
                                    divisions.map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                        (<Select.Option key={`${i}`} value={v.id}>{v.name}</Select.Option>)
                                    ))
                                }

                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label="Date">
                        {getFieldDecorator('date', {
                            initialValue: dateInitialVal,
                        })(
                            <DatePicker 
                                format="DD/MM/YYYY" 
                                minDate={dayjs().subtract(videoRetentionDays, 'days')}
                                allowClear={false}
                                suffixIcon={<Calendar />}
                            />,
                        )}
                    </Form.Item>
                    <Row>
                        <Col xs={12}>
                            <Form.Item label="Time From">
                                {getFieldDecorator('time_from', {
                                    initialValue: timeFromInitialVal,
                                    rules: [
                                        {
                                            pattern: /[0-9][0-9]:[0-9][0-9]:[0-9][0-9]/g,
                                            len: 8,
                                            message: 'Invalid format, must be in format "HH:MM:SS" e.g. 09:10:26',
                                        },
                                    ],
                                })(
                                    <Input placeholder='HH:MM:SS' />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item label="Time To">
                                {getFieldDecorator('time_to', {
                                    initialValue: timeToInitialVal,
                                    rules: [
                                        {
                                            pattern: /[0-9][0-9]:[0-9][0-9]:[0-9][0-9]/g,
                                            len: 8,
                                            message: 'Invalid format, must be in format "HH:MM:SS" e.g. 09:10:26',
                                        },
                                    ],
                                })(
                                    <Input placeholder='HH:MM:SS' />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="mt-2 text-center">
                        <Button
                            htmlType="submit"
                            type="primary"
                        >
                            Filter
                        </Button>
                    </div>
                </Form>
            )
        );
    }
}

VideoSearchFilterForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    divisions: PropTypes.array.isRequired,
    driverList: PropTypes.array.isRequired,
    assetReg: PropTypes.string,
    mapDate: PropTypes.string,
    form: PropTypes.object,
};

const VideoFileListFilter = Form.create({ name: 'video_search_filter_form' })(VideoSearchFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
        divisions: state.asset.divisions,
        driverList: state.driver.driverList,
        assetReg: state.global.videoSearchFilters.assetReg,
        selectedDate: state.global.videoSearchFilters.date,
        selectedTimeFrom: state.global.videoSearchFilters.timeFrom,
        selectedTimeTo: state.global.videoSearchFilters.timeTo,
        mapDate: state.global.mapDate,
        assetOptions: state.asset.options,
        mapSearchLocationBoundary: state.video.mapSearchLocationBoundary,
        mapSearchVideoSelectedDate: state.video.mapSearchVideoSelectedDate,
        mapSearchVideoSliderValue: state.video.mapSearchVideoSliderValue,
        searchParams: state.video.historicVideosSearchParams,
        videoRetentionDays: state.user.userCompany.video_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoFileListFilter);
