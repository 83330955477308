/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Select } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as userActions from '../../../core/user/userActions';

import Icon from '../../../components/elements/Icon';

const { Option } = Select;
const FormItem = Form.Item;

class UserCreateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    createUser = (e) => {
        const {
            form, actions, newUserPicture,
        } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                values.profile_picture_id = newUserPicture;
                actions.createUserRequest(values);
            }
        });
    }

    render() {
        const {
            form, currentUserRole,
        } = this.props;

        const { getFieldDecorator } = form;
        return (
            <Form id="asset-form" onSubmit={this.createUser} layout="vertical">
                <FormItem label="Name">
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter a name',
                            },
                        ],
                    })(
                        <Input
                            placeholder="Enter Name" />,
                    )}
                </FormItem>
                <FormItem label="Email">
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter email',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email',
                            },
                        ],
                    })(
                        <Input
                            placeholder="Enter Email" />,
                    )}

                </FormItem>
                <FormItem label="Role">
                    {getFieldDecorator('role', {
                        rules: [
                        ],
                        initialValue: 'user',
                    })(
                        <Select
                            style={{ width: '100%' }}
                        >
                            {currentUserRole === 'super-admin' || currentUserRole === 'admin' ? <Option value="manager">Manager</Option> : null}
                            <Option value="user">User</Option>
                            {currentUserRole === 'super-admin' ? <Option value="admin">Admin</Option> : null}
                            {currentUserRole === 'super-admin' ? <Option value="super-admin">Super Administrator</Option> : null}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="Status">
                    {getFieldDecorator('status', {
                        rules: [
                        ],
                        initialValue: '1',
                    })(
                        <Select
                            style={{ width: '100%' }}
                        >
                            <Option value="1">Active</Option>
                            <Option value="0">Inactive</Option>
                        </Select>,
                    )}
                </FormItem>
            </Form>
        );
    }
}

const WrappedUserCreateForm = Form.create({
    name: 'UserCreateForm',
})(UserCreateForm);

WrappedUserCreateForm.defaultProps = {
    updateForm: false,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        divisions: state.asset.divisions,
        vehicleTypes: state.asset.vehicleTypes,
        currentUserRole: state.user.role,
        departments: state.driver.departments,
        newUserPicture: state.user.newUserPicture,
        deviceGroups: state.user.deviceGroups,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WrappedUserCreateForm);
